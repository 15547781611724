import React, { useEffect, useCallback } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FiArrowLeft } from "react-icons/fi";
import NoticiaActions from "../../store/ducks/noticia";

import Menu from "../../components/Menu";
import Footer from "../../components/Footer";

import {
  Container,
  Content,
  Title,
  Description,
  Events,
  Box,
  TitleNews,
  DescriptionNews,
  Back,
} from "./styles";

export default function Noticia() {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const { data: blogNoticia } = useSelector((state) => state.noticia);

  const fetchData = useCallback(() => {
    if (id) {
      dispatch(
        NoticiaActions.fetchRequest({
          id,
        })
      );
    }
  }, [dispatch, id]);

  function navigation() {
    history.goBack();
  }

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Container>
      <Menu />
      <Content>
        <Back>
          <Link to="/" onClick={navigation} alt="Voltar" title="Voltar">
            <FiArrowLeft /> Voltar
          </Link>
        </Back>
        <Title>{blogNoticia.NO_TITULO}</Title>
        <Description>Confira as novidades da Sky eventos.</Description>
        <section>
          <Events>
            <Box>
              <img src={blogNoticia.url} alt={blogNoticia.TITULO} />
              <TitleNews />
              <DescriptionNews
                dangerouslySetInnerHTML={{ __html: blogNoticia.NO_TEXTO }}
              />
            </Box>
          </Events>
        </section>
      </Content>
      <Footer />
    </Container>
  );
}
