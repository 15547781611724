import React from "react";

import { Background, Title, Description } from "./styles";

export default function ComponentSobre() {
  return (
    <Background id="sobre">
      <Title>
        <span>Sobre</span> a sky
      </Title>
      <Description>
        <p>
          A Sky é uma empresa de produção de eventos referência no estado do Rio
          de Janeiro no segmento de festas e bailes de formaturas.
        </p>
        <p>
          Há mais de 14 anos no mercado, se consolidou pioneira em seus serviços
          prestados com a qualidade e excelência na produção de seus eventos,
          tendo como missão Realizar Sonhos com profissionalismo, surpreendendo
          seus clientes do início ao fim.
        </p>
      </Description>
    </Background>
  );
}
