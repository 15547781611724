import styled from "styled-components";

import ImageBackground from "../../assets/images/sobre/background-sobre.jpg";

export const Background = styled.section`
  background-image: url(${ImageBackground});
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding-top: 103px;
  padding-bottom: 103px;

  @media only screen and (max-width: 991px) {
    padding: 40px 0;
  }
`;

export const Title = styled.h1`
  font-size: 72px;
  line-height: 60px;
  max-width: 360px;
  color: #fff;
  margin: 0 auto;
  text-transform: uppercase;

  span {
    color: #f6d52e;
  }

  @media only screen and (max-width: 767px) {
    font-size: 60px;
    line-height: 62px;
    text-align: center;
  }

  @media only screen and (max-width: 425px) {
    font-size: 50px;
    line-height: 52px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 40px;
    line-height: 42px;
  }
`;

export const Description = styled.p`
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  text-align: center;
  max-width: 566px;
  margin: 0 auto;

  p + p {
    margin-top: 30px;
    margin-bottom: 24px;
  }

  @media only screen and (max-width: 991px) {
    padding: 0 25px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
  }
`;
