import React, { useState, useRef } from "react";
// import { Textarea } from "@rocketseat/unform";
import { Form } from "@unform/web";
import { Select, Input, InputMask, Textarea } from "../../components/Form";
import * as Yup from "yup";
import swal from "sweetalert";
import { FaSpinner } from "react-icons/fa";
import api from "../../services/api";

import {
  Container,
  Menu,
  BtnFecharModal,
  BtnAbrirModal,
  Content,
  Title,
  Description,
  BlockInput,
  BodyMenu,
  SubmitButton,
  GroupCouple,
} from "./styles";

const options = [
  { value: "Formaturas", label: "Formaturas" },
  { value: "Eventos Sociais", label: "Eventos Sociais" },
  { value: "Corporativo", label: "Corporativo" },
];

const options2 = [
  { value: "AEDB - Resende", label: "AEDB - Resende" },
  { value: "Estácio de Sá - Resende", label: "Estácio de Sá - Resende" },
  { value: "ETPC", label: "ETPC" },
  { value: "FAA", label: "FAA" },
  { value: "FASF", label: "FASF" },
  { value: "ICT", label: "ICT" },
  { value: "Macedo Soares", label: "Macedo Soares" },
  { value: "UBM - Barra Mansa", label: "UBM - Barra Mansa" },
  { value: "UBM - Cicuta", label: "UBM - Cicuta" },
  { value: "UERJ - Resende", label: "UERJ - Resende" },
  { value: "UFF - Aterrado", label: "UFF - Aterrado" },
  { value: "UFF - Vila", label: "UFF - Vila" },
  { value: "UGB - Barra do Piraí", label: "UGB - Barra do Piraí" },
  { value: "UGB - Volta Redonda", label: "UGB - Volta Redonda" },
  { value: "UNIFOA - Aterrado", label: "UNIFOA - Aterrado" },
  { value: "UNIFOA - Tangerinal", label: "UNIFOA - Tangerinal" },
  { value: "UNIFOA - Três Poços", label: "UNIFOA - Três Poços" },
  { value: "USS", label: "USS" },
  { value: "Outras", label: "Outras" },
];

export default function CompontentForm({ formPosition }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const formContact = useRef();

  async function handleSubmit(data, { reset }) {
    try {
      setLoading(true);
      formContact.current.setErrors({});
      const schema = Yup.object().shape({
        evento: Yup.string().required("Obrigatório"),
        instituicao: Yup.string().required("Obrigatório"),
        nome: Yup.string().required("Obrigatório"),
        email: Yup.string().email("E-mail inválido").required("Obrigatório"),
        celular: Yup.string().required("Obrigatório"),
        curso: Yup.string().required("Obrigatório"),
        conclusao: Yup.string()
          .max(4, "Verifique o ano digitado")
          .required("Obrigatório"),
        formandos: Yup.string().required("Obrigatório"),
        comissao: Yup.string().required("Obrigatório"),
        mensagem: Yup.string().required("Obrigatório"),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      const {
        evento,
        instituicao,
        nome,
        email,
        celular,
        curso,
        conclusao,
        formandos,
        comissao,
        mensagem,
      } = data;
      const post = {
        evento,
        instituicao,
        nome,
        email,
        celular,
        curso,
        ano_conclusao: conclusao,
        num_formandos: formandos,
        membros_comissao: comissao,
        mensagem,
      };

      const resp = await api.post("contatos", post, {
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (resp.status === 200) {
        setLoading(false);
        await swal("Enviado!", "Em breve retornaremos seu contato.", "success");
        setOpen(false);
        return reset();
      }
      setLoading(false);
      swal("Ops!", "Algo deu errado, tente novamente.", "error");
    } catch (err) {
      //console.log("Fonte do erro ", { err });
      setLoading(false);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        return formContact.current.setErrors(validationErrors);
      }

      return swal("Ops!", "Algo deu errado, tente novamente.", "error");
    }
    return false;
  }

  const form = useRef();
  function handleClick() {
    setOpen(!open);
  }

  function handleClickClose() {
    setOpen(false);
  }

  return (
    <Container className="containerMenu">
      <Menu formPosition={formPosition}>
        <BtnAbrirModal onClick={() => handleClick()}>
          <p>Solicitar orçamento</p>
        </BtnAbrirModal>

        <BodyMenu ref={form} open={open}>
          <BtnFecharModal>
            <a onClick={() => handleClickClose()} />
          </BtnFecharModal>

          <Content>
            <Title>
              Realize seu evento <span>com a sky</span>
            </Title>
            <Description>
              Quer um evento produzido pela Sky? Preencha o formulário abaixo e
              nós entramos em contato.
            </Description>

            <Form ref={formContact} onSubmit={handleSubmit}>
              <GroupCouple>
                <BlockInput>
                  <Select
                    name="evento"
                    placeholder="Evento"
                    options={options}
                  />
                </BlockInput>

                <BlockInput>
                  <Select
                    name="instituicao"
                    placeholder="Instituição"
                    options={options2}
                  />
                </BlockInput>
              </GroupCouple>

              <BlockInput className="line">
                <Input name="nome" placeholder="Nome Completo" />
              </BlockInput>

              <BlockInput className="line">
                <Input name="email" type="email" placeholder="E-mail" />
              </BlockInput>
              <BlockInput className="line">
                <InputMask
                  name="celular"
                  type="celular"
                  placeholder="Celular"
                />
              </BlockInput>

              <GroupCouple>
                <BlockInput>
                  <Input name="curso" type="text" placeholder="Curso" />
                </BlockInput>
                <BlockInput>
                  <Input
                    name="conclusao"
                    type="number"
                    placeholder="Ano de Conclusão"
                  />
                </BlockInput>
              </GroupCouple>

              <GroupCouple>
                <BlockInput>
                  <Input
                    name="formandos"
                    type="number"
                    placeholder="Nº de Formandos"
                  />
                </BlockInput>
                <BlockInput>
                  <Input
                    name="comissao"
                    type="number"
                    placeholder="Nº Membros da Comissão"
                  />
                </BlockInput>
              </GroupCouple>

              <BlockInput className="line">
                <Textarea name="mensagem" rows="8" placeholder="Mensagem" />
              </BlockInput>

              <SubmitButton type="submit">
                {loading ? (
                  <FaSpinner size={24} className="icon-spin" />
                ) : (
                  <button type="submit">Enviar Solicitação</button>
                )}
              </SubmitButton>
            </Form>
          </Content>
        </BodyMenu>
      </Menu>
    </Container>
  );
}
