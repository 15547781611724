import styled from "styled-components";

import ImagemBtnFecharMenu from "../../assets/images/menu/close-button.svg";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
`;

export const Menu = styled.div`
  background-color: transparent;
  ${(props) => props.formPosition && `position: ${props.formPosition}`};
  z-index: 11;
  width: 100%;
  margin-top: 0 auto;

  @media only screen and (max-width: 650px) {
  }
`;

export const BodyMenu = styled.div`
  width: 100%;
  display: ${(props) => (props.open ? "inline-block" : "none")};
  position: relative;
  z-index: 2;
  height: 100%;
  background-color: #f5f5f5;
  right: 0px;
  top: 0px;
  padding: 20px;
  overflow-y: auto;
  position: fixed;

  @media only screen and (max-width: 767px) {
    padding: 20px 0;
  }
`;

export const BtnFecharModal = styled.div`
  a {
    background-image: url(${ImagemBtnFecharMenu});
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    background-size: 50px 50px;
    display: block;
    float: right;
    margin-right: 28px;
    margin-top: 28px;
    cursor: pointer;
  }

  @media only screen and (max-width: 650px) {
    a {
      margin-top: 0;
      margin-right: 20px;
    }
  }
`;

export const BtnAbrirModal = styled.a`
  width: 100%;

  p {
    width: 100%;
    max-width: 261px;
    background-color: #e24a7e;
    text-align: center;
    margin: 48px auto 0 auto;
    padding: 19px 0;
    font-family: "PoppinsBold", sans-serif;
    font-size: 17px;
    line-height: 27px;
    text-transform: uppercase;
    color: #fff;
    border-radius: 33px;
    -webkit-border-radius: 33px;
    -moz-border-radius: 33px;
    box-shadow: rgba(0, 0, 0, 0.3) 3px 3px 12px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.3) 3px 3px 12px;
    -moz-box-shadow: rgba(0, 0, 0, 0.3) 3px 3px 12px;
    cursor: pointer;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 610px;
  margin: 0 auto;

  form {
    width: 100%;
    max-width: 610px;
    margin: 0 auto;

    @media only screen and (min-width: 575px) {
      .form-1 {
        position: relative;
        width: 48%;
        float: left;
      }
      .form-2 {
        position: relative;
        width: 48%;
        float: right;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    padding: 0 25px;
  }
`;

export const Title = styled.h1`
  font-size: 72px;
  line-height: 60px;
  max-width: 580px;
  text-align: center;
  color: #2b3476;
  margin: 80px auto 24px auto;
  text-transform: uppercase;

  span {
    color: #e24a7e;
  }

  @media only screen and (max-width: 767px) {
    font-size: 60px;
    line-height: 62px;
    text-align: center;
  }

  @media only screen and (max-width: 425px) {
    font-size: 50px;
    line-height: 52px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 40px;
    line-height: 42px;
  }
`;

export const Description = styled.p`
  font-size: 20px;
  line-height: 26px;
  color: #2f2f2f;
  max-width: 510px;
  text-align: center;
  margin: 0 auto 48px auto;

  @media only screen and (max-width: 991px) {
    padding: 0 25px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
  }
`;

export const GroupCouple = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 600px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const BlockInput = styled.div`
  display: flex;
  width: 45%;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 600px) {
    width: 100%;
  }
  &.line {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  input,
  select,
  textarea {
    font-family: "BarlowMedium", sans-serif;
    font-size: 17px;
    color: #000;

    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }
  textarea {
    padding: 20px;
  }
`;

export const SubmitButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 78px;

  button {
    width: 100%;
    max-width: 261px;
    font-family: "PoppinsBold", sans-serif;
    font-size: 17px;
    line-height: 27px;
    background-color: #323b7e;
    color: #fff;
    text-align: center;
    padding: 20px 0;
    margin: 0 auto;
    text-transform: uppercase;
    border-radius: 33px;
    -webkit-border-radius: 33px;
    -moz-border-radius: 33px;
  }

  .icon-spin {
    -webkit-animation: icon-spin 2s infinite linear;
    animation: icon-spin 2s infinite linear;
  }

  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
`;
