import { call, put } from "redux-saga/effects";

import api, { queryBuilder } from "../../services/api";
import NoticiasActions from "../ducks/noticias";

export function* fetch(action) {
  try {
    const { params = {} } = action;

    const query = queryBuilder(params);
    const url = `/noticias?${query}`;
    const { data: responseData } = yield call(api.get, url);
    const { data, ...pagination } = responseData;

    yield put(NoticiasActions.fetchSuccess(data, pagination));
  } catch (err) {
    yield put(NoticiasActions.fetchFailure(err.response.data.error.message));
  }
}
