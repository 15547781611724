import styled from "styled-components";

import { colors } from "../../styles/colors";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  font-family: "PoppinsBold", sans-serif;
  font-size: 16px;
  line-height: 32px;
  border-radius: 3px;
  background-color: ${({ active }) => (active ? colors.gray : "none")};
  border: ${({ disabled }) =>
    disabled ? `1px solid ${colors.disable}` : `1px solid ${colors.bege}`};
  cursor: ${({ disabled, active, ellipsis }) =>
    disabled || active || ellipsis ? "default" : "pointer"};
  color: ${({ active, disabled }) => {
    if (disabled) {
      return colors.disable;
    }
    if (active) {
      return colors.tealGray;
    }
    return colors.secundary;
  }};
  & + button {
    margin-left: 8px;
  }
`;
