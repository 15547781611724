import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 24px;
  textarea {
    resize: none;
  }

  input,
  select,
  textarea {
    font-family: "BarlowMedium", sans-serif;
    font-size: 17px;
    line-height: 28px;
    margin-top: 10px;
    width: 100%;
    padding: 12px;
    color: #e24a7e;
    border: 1px solid #909090;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    /* box-shadow: rgba(0,0,0,0.16) 0px 3px 6px;
  -webkit-box-shadow: rgba(0,0,0,0.16) 0px 3px 6px;
  -moz-box-shadow: rgba(0,0,0,0.16) 0px 3px 6px; */
    ::placeholder {
      color: #2c2c2c;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: #2c2c2c;
    }

    ::-ms-input-placeholder {
      color: #2c2c2c;
    }
  }

  span {
    font-size: 14px;
    font-family: "GilroySemiBold";
    color: #ff4367;
    margin-top: 4px;
  }
`;
