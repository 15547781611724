import { combineReducers } from "redux";

import { reducer as noticia } from "./noticia";
import { reducer as noticias } from "./noticias";
import { reducer as galeria } from "./galeria";
import { reducer as galerias } from "./galerias";
import { depoimentos } from "./depoimentos";
import { createDepoimento } from "./createDepoimento";
import { reducer as eventos } from "./eventos";
import { reducer as clubs } from "./club";

const reducers = combineReducers({
  noticia,
  noticias,
  galeria,
  galerias,
  depoimentos,
  createDepoimento,
  eventos,
  clubs,
});

export default reducers;
