import { call, put } from "redux-saga/effects";

import api from "../../services/api";
import NoticiaActions from "../ducks/noticia";

export function* fetch(action) {
  try {
    const { id } = action.params;
    const { data } = yield call(api.get, `noticias/${id}`);

    yield put(NoticiaActions.fetchSuccess(data));
  } catch (err) {
    yield put(NoticiaActions.fetchFailure(err.response.data.error.message));
  }
}
