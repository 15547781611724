import styled from "styled-components";

export const Background = styled.section`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding-top: 83px;
  padding-bottom: 76px;

  @media only screen and (max-width: 991px) {
    padding: 40px 0;
  }
`;

export const Slider = styled.div`
  img {
    max-width: 115px;
    object-fit: cover;
  }
`;

export const Nome = styled.h2`
  font-family: "PoppinsBold", sans-serif;
  font-size: 20px;
  line-height: 32px;
  margin-top: 16px;
  color: #fff;
  text-transform: uppercase;
`;

export const Curso = styled.p`
  font-family: "PoppinsMedium", sans-serif;
  font-size: 15px;
  line-height: 19px;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #FFF6C7;
  text-transform: uppercase;
`;

export const Comentario = styled.p`
  font-family: "BarlowMedium", sans-serif;
  font-size: 15px;
  line-height: 19px;
  color: #fff;
`;

export const Title = styled.h1`
  font-size: 72px;
  line-height: 60px;
  max-width: 580px;
  text-align: center;
  color: #2B3476;
  margin: 0 auto 16px auto;
  text-transform: uppercase;

  @media only screen and (max-width: 767px) {
    font-size: 60px;
    line-height: 62px;
    text-align: center;
  }

  @media only screen and (max-width: 425px) {
    font-size: 50px;
    line-height: 52px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 40px;
    line-height: 42px;
    padding: 0 25px;
  }
`;

export const Description = styled.p`
  font-size: 20px;
  line-height: 26px;
  color: #2F2F2F;
  max-width: 510px;
  text-align: center;
  margin: 0 auto;

  @media only screen and (max-width: 991px) {
    padding: 0 25px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
  }
`;
