import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "@unform/web";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import { withRouter } from "react-router-dom";
import Swiper from "react-id-swiper";
import * as Yup from "yup";
import swal from "sweetalert";
import { FaSpinner } from "react-icons/fa";
import DepoimentosActions from "../../store/ducks/depoimentos";
import "swiper/css/swiper.css";

import {
  Background,
  Slider,
  Nome,
  Curso,
  Comentario,
  ButtonReadMore,
  Modal,
  BoxModal,
  GroupFields,
  Title,
  Description,
  Event,
  Container,
  ButtonDepoimentos,
  ButtonFormando,
  Arrow,
  BodyMenuModal,
  BtnFecharModal,
  BtnFecharModalDepo,
  ContentModal,
  TitleModal,
  DescriptionModal,
  Loader,
  SubmitButton,
} from "./styles";
import api from "../../services/api";
import closeButton from "../../assets/images/comentarios/closeButton.svg";

import { Input, InputFile, InputMask, Textarea } from "../../components/Form/";
// import InputComponent from "../Input";
// import InputFile from "../InputFile";
// import { InputMask } from "../../components/Form";

function ComponentComentario() {
  const [swiper, updateSwiper] = useState(null);
  const [selected, setSelected] = useState(null);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const formModal = useRef();
  const formRef = useRef(null);

  const { data: depoimentos } = useSelector((state) => state.depoimentos);

  function handleShow(DE_COD) {
    const filter = depoimentos.filter(
      (comentario) => comentario.DE_COD === DE_COD
    );
    setSelected(filter[0]);
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleClickModal() {
    setOpenModal(!openModal);
  }

  function handleClickClose() {
    setOpenModal(false);
  }

  function htmlTextToString(htmlText) {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = htmlText;
    return textArea.value;
  }

  const settings = {
    slidesPerView: 3,
    spaceBetween: 100,
    speed: 500,
    centeredSlides: false,
    shouldSwiperUpdate: true,
    pagination: {
      clickable: true,
      renderBullet(index, className) {
        return `<span class="${className}"></span>`;
      },
    },
    breakpoints: {
      1280: {
        slidesPerView: 3,
        centeredSlides: false,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 2,
        centeredSlides: false,
        spaceBetween: 40,
      },
      928: {
        slidesPerView: 2,
        centeredSlides: false,
        spaceBetween: 40,
      },
      768: {
        slidesPerView: 1,
        centeredSlides: true,
      },
      640: {
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 40,
      },
      320: {
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 40,
      },
    },
  };
  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };
  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  const getDepoimentos = useCallback(() => {
    dispatch(DepoimentosActions.fetchRequest());
  }, [dispatch]);

  useEffect(() => {
    getDepoimentos();
  }, [getDepoimentos]);

  async function handleSubmit(data, { reset }) {
    try {
      setLoading(true);
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        DE_NOME: Yup.string().required("Campo Obrigátorio"),
        DE_ANO: Yup.string().required("Campo Obrigátorio"),
        DE_CURSO: Yup.string().required("Campo Obrigátorio"),
        DE_FACULDADE: Yup.string().required("Campo Obrigátorio"),
        DE_DEPOIMENTO: Yup.string().required("Campo Obrigátorio"),
        DE_FOTO: Yup.string().required("Selecione uma foto"),
      });

      await schema.validate(data, { abortEarly: false });

      const formData = new FormData();
      formData.append("DE_NOME", data.DE_NOME);
      formData.append("DE_ANO", data.DE_ANO);
      formData.append("DE_CURSO", data.DE_CURSO);
      formData.append("DE_FACULDADE", data.DE_FACULDADE);
      formData.append("DE_DEPOIMENTO", data.DE_DEPOIMENTO);
      formData.append("image", data.DE_FOTO);

      const resp = await api.post("depoimentos", formData);

      if (resp.status === 200) {
        setLoading(false);
        await swal("Enviado!", "Em breve retornaremos seu contato.", "success");
        setOpenModal(false);
        return reset();
      }
    } catch (err) {
      setLoading(false);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <>
      <Background>
        <Title>Eu escolhi sky!</Title>
        <Description>
          Formaturas inesquecíveis, segundo os próprios estudantes.
        </Description>
        <Event>
          <Swiper getSwiper={updateSwiper} {...settings}>
            {depoimentos.map((i) => (
              <Slider key={i.id}>
                <img src={i.url} alt={i.DE_NOME} />
                <Nome>{i.DE_NOME}</Nome>
                <Curso>{i.DE_CURSO}</Curso>
                <Comentario className="limit-text">
                  {htmlTextToString(
                    i.DE_DEPOIMENTO.replace(/(<([^>]+)>)/gi, "")
                  )}
                </Comentario>
                <ButtonReadMore
                  key={i.DE_COD}
                  type="button"
                  onClick={() => handleShow(i.DE_COD)}>
                  Leia Mais
                </ButtonReadMore>
              </Slider>
            ))}
          </Swiper>
          <Arrow direction="left" onClick={goPrev}>
            <MdChevronLeft />
          </Arrow>
          <Arrow direction="right" onClick={goNext}>
            <MdChevronRight />
          </Arrow>
        </Event>
        <Container>
          <ButtonDepoimentos onClick={() => handleClickModal()}>
            envie seu depoimento
          </ButtonDepoimentos>
          <ButtonFormando>
            <a
              href="https://formando.online/index.aspx?ReturnUrl=%2f"
              target="_blank"
              rel="noreferrer">
              acessar área do formando
            </a>
          </ButtonFormando>
        </Container>
      </Background>
      <Modal open={open}>
        <BoxModal>
          <BtnFecharModal onClick={handleClose}>
            <img src={closeButton} />
          </BtnFecharModal>
          {selected && <img src={selected.url} alt={selected.DE_NOME} />}
          <Nome>{selected && selected.DE_NOME}</Nome>
          <Curso>{selected && selected.DE_CURSO}</Curso>
          <Comentario>
            {htmlTextToString(
              selected && selected.DE_DEPOIMENTO.replace(/(<([^>]+)>)/gi, "")
            )}
          </Comentario>
        </BoxModal>
      </Modal>

      <BodyMenuModal ref={formModal} openModal={openModal}>
        <BtnFecharModalDepo>
          <a onClick={() => handleClickClose()} />
        </BtnFecharModalDepo>

        <ContentModal>
          <TitleModal>envie seu depoimento</TitleModal>
          <DescriptionModal>
            Formaturas inesquecíveis, segundo os próprios estudantes.
          </DescriptionModal>
          <Form id="contanto" ref={formRef} onSubmit={handleSubmit}>
            <Input
              textinput="true"
              name="DE_NOME"
              placeholder="Nome Completo"
            />

            <GroupFields>
              <div className="myLeft">
                <InputMask name="DE_ANO" placeholder="Ano" type="ano" />
              </div>
              <div className="myRight">
                <Input textinput="true" name="DE_CURSO" placeholder="Curso" />
              </div>
            </GroupFields>

            <GroupFields>
              <div className="myLeft">
                <Input
                  textinput="true"
                  name="DE_FACULDADE"
                  placeholder="Faculdade"
                />
              </div>
              <div className="myRight">
                <InputFile
                  textinput="true"
                  name="DE_FOTO"
                  id="DE_FOTO"
                  type="file"
                  placeholder="Envie sua foto"
                />
              </div>
            </GroupFields>

            <Textarea
              rows="8"
              id="DE_DEPOIMENTO"
              name="DE_DEPOIMENTO"
              placeholder="Seu Depoimento"
            />

            <SubmitButton type="submit">
              {loading ? (
                <FaSpinner size={24} className="icon-spin" />
              ) : (
                <button type="submit">Enviar depoimento</button>
              )}
            </SubmitButton>
          </Form>
        </ContentModal>
      </BodyMenuModal>
    </>
  );
}

export default withRouter(ComponentComentario);
