import React from "react";

import { Container } from "./styles";
import Banner from "../../components/Banner";
import Formatura from "../../components/FormaturaSocial";
import JaRolou from "../../components/JaRolou";
import Comentarios from "../../components/Comentarios";
import VaiRolar from "../../components/VaiRolar";
import SkyClub from "../../components/SkyClub";
import Sobre from "../../components/Sobre";
import SigaSky from "../../components/SigaSky";
import Evento from "../../components/RealizeEvento";
import SkyNews from "../../components/SkyNews";
import Footer from "../../components/Footer";

export default function Main() {
  return (
    <Container>
      <Banner />
      <Formatura />
      <JaRolou />
      <Comentarios />
      <VaiRolar />
      <SkyClub />
      <Sobre />
      <SigaSky />
      <Evento />
      <SkyNews />
      <Footer />
    </Container>
  );
}
