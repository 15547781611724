import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 16px;
  &:last-child {
    margin-right: 0;
  }
  label {
    font-family: "Lato";
    display: block;
    font-weight: 600;
    margin-bottom: 10px;
    color: #000;
  }
  input {
    width: 100%;
    height: 54px;

    font-size: 14px;
    font-family: "Lato", sans-serif;
    color: red;
  }
  span.error {
    display: block;
    font-size: 14px;
    font-family: "GilroySemiBold";
    color: #ff4367;
    margin-bottom: 4px;
    margin-top: 4px;
  }
`;
