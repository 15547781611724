import { call, put } from "redux-saga/effects";

import api, { queryBuilder } from "../../services/api";
import GaleriasActions from "../ducks/galerias";

export function* fetch(action) {
  try {
    const { params = {} } = action;

    const query = queryBuilder(params);
    const url = `/galerias?${query}`;
    const { data: responseData } = yield call(api.get, url);
    const { data, ...pagination } = responseData;

    yield put(GaleriasActions.fetchSuccess(data, pagination));
  } catch (err) {
    yield put(GaleriasActions.fetchFailure(err.response.data.error.message));
  }
}
