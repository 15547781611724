import styled, { css } from "styled-components";

import imageBackground from "../../assets/images/comentarios/background.png";
import ImagemBtnFecharMenu from "../../assets/images/menu/close-button.svg";
import Loading from "../../assets/images/loading/loading.gif";

export const Background = styled.section`
  background-image: url(${imageBackground});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  background-color: #fff;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 93px;

  @media only screen and (max-width: 991px) {
    padding: 40px 0;
  }
`;

export const Slider = styled.div`
  img {
    max-width: 115px;
    object-fit: cover;
    border-radius: 50%;
  }

  @media only screen and (max-width: 480px) {
    text-align: center;
  }
`;
export const Nome = styled.h2`
  font-family: "PoppinsBold", sans-serif;
  font-size: 20px;
  line-height: 32px;
  margin-top: 16px;
  color: #fff;
  text-transform: uppercase;
`;

export const Curso = styled.p`
  font-family: "PoppinsMedium", sans-serif;
  font-size: 15px;
  line-height: 19px;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #fff6c7;
  text-transform: uppercase;
`;

export const Comentario = styled.p`
  font-family: "BarlowMedium", sans-serif;
  font-size: 15px;
  line-height: 19px;
  color: #fff;

  &.limit-text {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  @media only screen and (max-width: 480px) {
    max-width: 240px;
    margin: 0 auto;
  }

  @media only screen and (max-width: 375px) {
    max-width: 180px;
  }
`;

export const ButtonReadMore = styled.button`
  font-family: "PoppinsMedium", sans-serif;
  font-size: 15px;
  line-height: 19px;
  margin-top: 10px;
  color: #fff6c7;
`;

export const Modal = styled.div`
  display: ${(props) => (props.open ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 50;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const BoxModal = styled.div`
  position: relative;
  background-color: rgba(51, 23, 123, 0.9);
  border-radius: 5px;
  width: 100%;
  max-width: 430px;
  height: 100%;
  max-height: 529px;
  text-align: center;
  padding: 40px 0;

  img {
    width: 100%;
    max-width: 115px;
    border-radius: 50%;
  }

  ${() => `
    ${Comentario} {
      padding: 0 40px;
      max-height: 270px;
      overflow: auto;
    }
  `}

  @media only screen and (max-width: 575px) {
    width: 85%;
    height: 90%;

    ${() => `
      ${Comentario} {
        padding: 0;
        max-width: 220px;
        max-height: 230px;
      }
    `}
  }
`;

export const BtnFecharModal = styled.a`
  position: absolute;
  max-width: 32px;
  top: -20px;
  right: -20px;
  cursor: pointer;
`;

export const Title = styled.h1`
  font-size: 72px;
  line-height: 60px;
  max-width: 315px;
  text-align: center;
  color: #fff;
  margin: 0 auto 16px auto;
  text-transform: uppercase;

  @media only screen and (max-width: 991px) {
    padding: 0 25px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 60px;
    line-height: 62px;
    text-align: center;
  }

  @media only screen and (max-width: 425px) {
    font-size: 50px;
    line-height: 52px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 40px;
    line-height: 42px;
  }
`;

export const Description = styled.p`
  font-size: 19px;
  line-height: 19px;
  color: #fff;
  margin: 0 auto;

  @media only screen and (max-width: 991px) {
    padding: 0 25px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
  }
`;

export const Event = styled.div`
  width: 83%;
  padding: 0 70px;
  margin-top: 65px;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: 768px) {
    padding: 0;
    width: 35%;
  }

  @media only screen and (max-width: 480px) {
    .swiper-wrapper .swiper-slide-active {
      padding-left: 0;
    }
  }
`;

export const ButtonLink = styled.a`
  width: 100%;
  max-width: 316px;
  margin: 44px auto 0 auto;
  padding: 15px 0;
  text-align: center;
  background-color: #fff;
  font-family: "PoppinsBold", sans-serif;
  font-size: 17px;
  line-height: 27px;
  text-transform: uppercase;
  color: #e24a7e;
  border-radius: 29px;
  -webkit-border-radius: 29px;
  -moz-border-radius: 29px;
  box-shadow: rgba(0, 0, 0, 0.3) 3px 3px 12px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 3px 3px 12px;
  -moz-box-shadow: rgba(0, 0, 0, 0.3) 3px 3px 12px;

  @media only screen and (max-width: 575px) {
    max-width: 280px;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const Arrow = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 35px;
  height: 35px;
  background-color: #fff;
  color: #242f7c;
  border-radius: 45px;
  border: none;

  ${(props) =>
    props.direction === "left"
      ? css`
          left: 9%;
        `
      : css`
          right: 9%;
        `}
  top: 45%;
  z-index: 2;
  font-size: 3.2rem;

  @media screen and (max-device-width: 320px) and (-webkit-min-device-pixel-ratio: 1) {
    ${(props) =>
      props.direction === "left"
        ? css`
            left: 14%;
          `
        : css`
            right: 14%;
          `}

    svg {
      position: relative;
      top: -8px;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px 0;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    button {
      width: 70%;
      margin-bottom: 20px;
    }
  }
`;
export const ButtonDepoimentos = styled.button`
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background: #1ea6c3;
  max-width: 316px;
  height: 53px;
  text-transform: uppercase;
  padding: 12px 31px;
  border-radius: 6px;
  margin-left: 35px;

  @media (max-width: 425px) {
    font-family: "PoppinsBold", sans-serif;
    max-width: 290px;
    padding: 12px 65px;
    font-size: 12px;
  }
`;
export const ButtonFormando = styled.button`
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #323b7e;
  background: #fff;
  max-width: 316px;
  height: 53px;
  text-transform: uppercase;
  padding: 12px 31px;
  border-radius: 6px;
  margin-left: 35px;

  @media (max-width: 425px) {
    font-family: "PoppinsBold", sans-serif;
    max-width: 290px;
    padding: 12px 46px;
    font-size: 12px;
  }
`;

export const BodyMenuModal = styled.div`
  width: 100%;
  display: ${(props) => (props.openModal ? "inline-block" : "none")};
  position: relative;
  z-index: 2;
  height: 100%;
  background-color: #f5f5f5;
  right: 0px;
  top: 0px;
  padding: 20px;
  overflow-y: auto;
  position: fixed;

  @media only screen and (max-width: 767px) {
    padding: 20px 0;
  }
`;

export const BtnFecharModalDepo = styled.div`
  a {
    background-image: url(${ImagemBtnFecharMenu});
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    background-size: 50px 50px;
    display: block;
    float: right;
    margin-right: 28px;
    margin-top: 28px;
    cursor: pointer;
  }

  @media only screen and (max-width: 650px) {
    a {
      margin-top: 0;
      margin-right: 20px;
    }
  }
`;

export const ContentModal = styled.div`
  width: 100%;
  max-width: 610px;
  margin: 0 auto;

  form {
    width: 90%;
    max-width: 610px;
    margin: 0 auto;
  }

  form input {
    font-family: "BarlowMedium", sans-serif;
    font-size: 17px;
    color: #333;
    border: 1px solid #909090;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
  }
  form Textarea {
    font-family: "BarlowMedium", sans-serif;
    font-size: 17px;
    border: 1px solid #909090;
    padding: 20px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
  }
`;

export const GroupFields = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div.myLeft {
    width: 48%;
  }
  div.myRight {
    width: 48%;
  }
  @media only screen and (max-width: 380px) {
    flex-direction: column;
    div.myLeft {
      width: 100%;
      margin-bottom: 10px;
    }
    div.myRight {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

export const TitleModal = styled.h1`
  display: block;
  font-size: 72px;
  max-width: 580px;
  text-align: center;
  color: #2b3476;
  margin: 80px auto 24px auto;
  text-transform: uppercase;

  @media only screen and (max-width: 767px) {
    font-size: 60px;
    line-height: 62px;
    text-align: center;
  }

  @media only screen and (max-width: 425px) {
    font-size: 50px;
    line-height: 52px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 40px;
    line-height: 42px;
  }
`;

export const DescriptionModal = styled.p`
  display: block;
  margin-top: 20px;
  line-height: 26px;
  color: #2f2f2f;
  max-width: 510px;
  text-align: center;
  margin: 0 auto 48px auto;
`;

export const SubmitButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 78px;

  button {
    width: 100%;
    max-width: 261px;
    font-family: "PoppinsBold", sans-serif;
    font-size: 17px;
    line-height: 27px;
    background-color: #323b7e;
    color: #fff;
    text-align: center;
    padding: 20px 0;
    margin: 0 auto;
    text-transform: uppercase;
    border-radius: 33px;
    -webkit-border-radius: 33px;
    -moz-border-radius: 33px;
  }

  .icon-spin {
    -webkit-animation: icon-spin 2s infinite linear;
    animation: icon-spin 2s infinite linear;
  }

  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
`;
