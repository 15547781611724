import React from "react";
import { Switch, Route } from "react-router-dom";

import Main from "../pages/Main";
import Album from "../pages/galerias";
import Noticias from "../pages/Noticias";
import Noticia from "../pages/Noticia";
import Galeria from "../pages/galeria";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Main} />
      <Route path="/jarolou" exact component={Album} />
      <Route path="/noticias" exact component={Noticias} />
      <Route path="/noticias/:titulo/:id" exact component={Noticia} />
      <Route path="/jarolou/:album/:id" component={Galeria} />
    </Switch>
  );
}
