/* eslint-disable no-underscore-dangle */
import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

const { Types, Creators } = createActions(
  {
    fetchRequest: ["params"],
    fetchSuccess: ["data"],
    fetchFailure: ["error"],
    reset: [],
  },
  { prefix: "NOTICIA_" }
);

export const NoticiaTypes = Types;
export default Creators;

export const INITIAL_STATE = {
  data: [],
  loading: true,
  error: null,
};

// fetch
export const _fetchRequest = (state) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });
export const _fetchSuccess = (state, action) =>
  update(state, {
    loading: { $set: false },
    data: { $set: action.data },
  });
export const _fetchFailure = (state, action) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

export const _reset = () => INITIAL_STATE;

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FETCH_REQUEST]: _fetchRequest,
  [Types.FETCH_SUCCESS]: _fetchSuccess,
  [Types.FETCH_FAILURE]: _fetchFailure,
  [Types.RESET]: _reset,
});
