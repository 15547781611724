import React, { useCallback, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ptBR } from "date-fns/locale";

import { parseISO, format, formatDistanceStrict } from "date-fns";

import EventosActions from "../../store/ducks/eventos";

import {
  Background,
  Container,
  Title,
  Description,
  Box,
  EventDay,
  Type,
  Remain,
} from "./styles";

function ComponentVaiRolar() {
  const dispatch = useDispatch();

  const { data: dataEventos } = useSelector((state) => state.eventos);

  const getEventos = useCallback(() => {
    dispatch(EventosActions.fetchRequest());
  }, [dispatch]);

  useEffect(() => {
    getEventos();
  }, [getEventos]);

  function Parsedata(data) {
    const parsedDate = parseISO(data);
    const formatData = format(parsedDate, "dd/MMM", { locale: ptBR });
    return formatData;
  }

  function diasRestante(data) {
    const dataAtual = format(new Date(), "yyyy-MM-dd");
    const firstDate = parseISO(dataAtual);
    const parsedDate = parseISO(data);

    const distance = formatDistanceStrict(firstDate, parsedDate, {
      unit: "day",
      roundingMethod: "ceil",
      locale: ptBR,
    });
    return distance;
  }

  return (
    <Background id="vai-rolar">
      <Container>
        <Title>
          O que vai rolar<span className="dot">.</span>
        </Title>
        <Description>Confira o que vem por aí.</Description>
        {dataEventos.map((i) => (
          <Box key={i.EV_COD}>
            <EventDay>
              {Parsedata(i.EV_DATA).split("/")[0]}
              <p> {Parsedata(i.EV_DATA).split("/")[1]}</p>
            </EventDay>
            <Type>{i.EV_TIPO}</Type>

            <Remain>{diasRestante(i.EV_DATA)} restantes</Remain>
          </Box>
        ))}
      </Container>
    </Background>
  );
}

export default withRouter(ComponentVaiRolar);
