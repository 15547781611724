import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 16px;
  margin-bottom: 24px;

  &:last-child {
    margin-right: 0;
  }

  label {
    font-family: "GilroySemiBold";
    font-size: 18px;
    color: #000;
    margin-bottom: 8px;
  }

  input {
    width: 100%;
    height: 60px;
    border: 1px #908c8c solid;
    padding: 16px;
    font-size: 17px;
    color: #000;
  }

  input[type="color"] {
    padding: 8px;
    border: none;
    color: transparent;
  }

  span {
    font-size: 14px;
    font-family: "GilroySemiBold";
    color: #ff4367;
    margin-top: 4px;
  }
`;
