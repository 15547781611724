import React, { useEffect, useCallback, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { FiArrowLeft } from "react-icons/fi";
import closeButton from "../../assets/images/comentarios/closeButton.svg";

// import { FiLoader } from "react-icons/fi";

import GaleriaActions from "../../store/ducks/galeria";

import Menu from "../../components/Menu";
import Footer from "../../components/Footer";
// import Paginator from "../../components/Paginator";

import {
  Container,
  Content,
  Title,
  Description,
  Events,
  Box,
  Modal,
  BoxModal,
  BtnFecharModal,
  Back,
} from "./styles";

export default function Album() {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();
  const [title, setTitle] = useState(null);

  const { data: galeriaData } = useSelector((state) => state.galeria);

  function handleModal(IM_COD) {
    const filter = galeriaData.filter((galeria) => galeria.IM_COD === IM_COD);
    setSelected(filter[0]);
    setOpen(true);
  }

  useEffect(() => {
    if (galeriaData.length > 0) {
      setTitle(galeriaData[0].GA_NOME);
    }
  }, [galeriaData]);

  function handleClose() {
    setOpen(false);
  }

  function navigation() {
    history.goBack();
  }

  const fetchData = useCallback(() => {
    if (id) {
      dispatch(
        GaleriaActions.fetchRequest({
          id,
        })
      );
    }
  }, [dispatch, id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const url = "https://www.sejaskyeventos.com.br/img/galerias";

  return (
    <Container>
      <Menu />
      <Content>
        <Back>
          <Link to="/" onClick={navigation} alt="Voltar" title="Voltar">
            <FiArrowLeft /> Voltar
          </Link>
        </Back>
        <Title>{title}</Title>
        <Description>
          Confira as fotos dos eventos produzidos pela Sky!
        </Description>

        <Events>
          {galeriaData.map((galeria) => (
            <Box key={galeria.IM_COD}>
              <img
                src={`${url}/${galeria.GA_URL}/${galeria.IM_URL}`}
                alt={galeria.GA_NOME}
                onClick={() => handleModal(galeria.IM_COD)}
              />
            </Box>
          ))}
        </Events>
        <Modal open={open}>
          <BoxModal>
            <BtnFecharModal onClick={handleClose}>
              <img src={closeButton} />
            </BtnFecharModal>

            {selected && (
              <img
                src={`${url}/${selected.GA_URL}/${selected.IM_URL}`}
                alt={selected.GA_NOME}
              />
            )}
          </BoxModal>
        </Modal>

        {/* {galeriaPagination && (
          <Paginator pagination={galeriaPagination} onPageChange={handlePage} />
        )} */}
      </Content>
      <Footer />
    </Container>
  );
}
