import React from "react";
import { FaInstagram, FaFacebookF } from "react-icons/fa";
import { Container, Title, Description, RedesSociais } from "./styles";

function SigaSky() {
  return (
    <Container>
      <Title>siga a sky</Title>
      <Description>
        Acompanhe nossas redes sociais e fique por dentro das novidades!
      </Description>
      <RedesSociais>
        <span>
          <a href="https://www.instagram.com/sejaskyeventos" target="_blank">
            <FaInstagram />
            @sejasskyeventos
          </a>
        </span>
        <span>
          <a href="https://www.facebook.com/sejaskyeventos" target="_blank">
            <FaFacebookF />
            @sejasskyeventos
          </a>
        </span>
      </RedesSociais>
    </Container>
  );
}

export default SigaSky;
