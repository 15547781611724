import { call, put } from "redux-saga/effects";

import api, { queryBuilder } from "../../services/api";
import ClubsActions from "../ducks/club";

export function* fetch(action) {
  try {
    const { params = {} } = action;

    const query = queryBuilder(params);
    const url = `/club?${query}`;
    const { data } = yield call(api.get, url);

    yield put(ClubsActions.fetchSuccess(data));
  } catch (err) {
    yield put(ClubsActions.fetchFailure(err.response.data.error.message));
  }
}
