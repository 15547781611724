import React, { useRef, useEffect } from "react";
import ReactSelect from "react-select";
import { useField } from "@unform/core";
import { Container } from "./styles";

export default function Select({ name, label, required, ...rest }) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
      backgroundColor: "transparent",
      height: 60,
      color: "#000",
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "rgba(255,255,255,1)",
      padding: 8,
      fontFamily: "BarlowMedium",
      border: `1px #6583a2 solid`,
      borderRadius: 10,
      height: 60,
      color: "#000",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontFamily: "BarlowMedium",
      fontSize: 17,
      color: "#000",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
    }),
    valueContainer: (provided) => ({
      ...provided,
      fontFamily: "BarlowMedium",
      fontSize: 17,
      color: "#000",
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: `1px #ddd solid`,
      padding: 16,
      fontFamily: "BarlowMedium",
      fontSize: 14,
      boxSizing: "border-box",
      outline: "none",
      color: state.isSelected ? "#000" : "#404040",
      backgroundColor: state.isSelected ? " #eee" : "transparent",
    }),
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: "state.value",
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option) => option.value);
        }
        if (!ref.state.value) {
          return "";
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <Container>
      <label htmlFor={fieldName}>
        {label}
        {required && <span className="danger">*</span>}
      </label>
      <ReactSelect
        name={fieldName}
        styles={customStyles}
        defaultValue={defaultValue}
        ref={selectRef}
        classNamePrefix="react-select"
        {...rest}
      />
      {error && <span className="danger">{error}</span>}
    </Container>
  );
}
