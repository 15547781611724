import React from "react";
import { withRouter } from "react-router-dom";

import { Background, Title, Description } from "./styles";

import ModalForm from "../ModalForm";

function ComponentEvento() {
  return (
    <Background id="evento">
      <Title>Realize seu evento</Title>
      <Description>
        Quer um evento produzido pela Sky? Preencha o formulário abaixo e nós
        entramos em contato.
      </Description>
      <ModalForm />
    </Background>
  );
}

export default withRouter(ComponentEvento);
