import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FiSearch } from "react-icons/fi";
import { FaSpinner } from "react-icons/fa";
import NoticiasActions from "../../store/ducks/noticias";

import Menu from "../../components/Menu";
import Footer from "../../components/Footer";
import Paginator from "../../components/Paginator";

import {
  Container,
  Content,
  Title,
  Description,
  Events,
  Box,
  TitleNews,
  DescriptionNews,
} from "./styles";

export default function Noticias() {
  const dispatch = useDispatch();

  const [query, setQuery] = useState({
    search: "",
    limit: 3,
  });

  const {
    data: blogNoticias,
    pagination: blogPaginator,
    loading: noticiasLoading,
  } = useSelector((state) => state.noticias);

  function handleSearch(event) {
    const { value } = event.target;
    setQuery((state) => ({ ...state, search: value }));
  }

  function handlePage(page) {
    dispatch(NoticiasActions.fetchRequest({ ...query, page }));
  }

  const getPosts = useCallback(() => {
    dispatch(NoticiasActions.fetchRequest(query));
  }, [dispatch, query]);

  useEffect(() => {
    getPosts();
  }, [getPosts]);

  return (
    <Container>
      <Menu />
      <Content>
        <Title>
          Sky news<span>.</span>
        </Title>
        <Description>Confira as novidades da Sky eventos.</Description>
        <section>
          <div>
            <button type="submit">
              {noticiasLoading ? (
                <FaSpinner size={24} className="icon-spin" />
              ) : (
                <FiSearch />
              )}
            </button>
            <input
              name="search"
              onChange={handleSearch}
              placeholder="Buscar notícia..."
            />
          </div>
          {!noticiasLoading && blogNoticias.length > 0 ? (
            <>
              <Events>
                {blogNoticias.map((noticia) => (
                  <Box key={noticia.NO_COD} noticia={noticia}>
                    <img src={noticia.url} alt={noticia.titulo} />
                    <TitleNews>
                      <Link
                        to={`noticias/${noticia.NO_TITULO.toLowerCase().replace(
                          / /g,
                          "-"
                        )}/${noticia.NO_COD}`}>
                        {`${noticia.NO_TITULO}`}
                      </Link>
                    </TitleNews>
                    <DescriptionNews
                      className="limit-text"
                      dangerouslySetInnerHTML={{ __html: noticia.NO_TEXTO }}
                    />
                  </Box>
                ))}
              </Events>
              <Paginator pagination={blogPaginator} onPageChange={handlePage} />
            </>
          ) : (
            <span>Nenhum resultado encontrado!</span>
          )}
        </section>
      </Content>
      <Footer />
    </Container>
  );
}
