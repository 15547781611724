import styled from "styled-components";

export const Banner = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 767px;

  img.background {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }

  .containerMenu {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  @media only screen and (max-width: 767px) {
    height: 550px;

    .containerMenu {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
`;

export const Text = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 767px) {
    padding: 0 25px;
  }
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 100px;
  line-height: 80px;
  text-transform: uppercase;
  margin-bottom: 24px;
  max-width: 470px;
  margin: 0 auto;
  color: #fff;

  span.title-border {
    color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #fff;
  }

  span.dot {
    color: #F6D52E;
  }

  @media only screen and (max-width: 767px) {
    font-size: 60px;
    line-height: 62px;
  }

  @media only screen and (max-width: 425px) {
    font-size: 50px;
    line-height: 52px;
  }
`;

export const Description = styled.p`
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  color: #fff;

  @media only screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 18px;
  }
`;

export const Button = styled.a`
  font-family: "PoppinsBold", sans-serif;
  font-size: 13px;
  line-height: 26px;
  display: grid;
  position: absolute;
  margin: 0 auto;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  color: #F6D52E;

  img {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;
