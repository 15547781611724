import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";

import NoticiasActions from "../../store/ducks/noticias";

import {
  Background,
  Title,
  Description,
  News,
  Box,
  NewsTitle,
  NewsData,
  Button,
} from "./styles";

function ComponentSkyNews() {
  const dispatch = useDispatch();

  const [params, setParams] = useState({ limit: 3 });

  const { data: blogNoticias } = useSelector((state) => state.noticias);

  const getPosts = useCallback(() => {
    dispatch(NoticiasActions.fetchRequest(params));
  }, [dispatch, params]);

  useEffect(() => {
    getPosts();
  }, [getPosts, setParams]);

  function Parsedata(data) {
    const parsedDate = parseISO(data);
    const formatData = format(parsedDate, "dd 'de' MMMM 'de' yyyy", {
      locale: ptBR,
    });
    return formatData;
  }

  const RenderSkyNews = blogNoticias.map((i) => (
    <Box key={i.NO_COD}>
      <img src={i.url} alt={i.NO_TITULO} />
      <NewsTitle>
        <a href={`noticias/${i.NO_TITULO.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9 -]/g, '').replace(/ /g,"-").toLowerCase()}/${i.NO_COD}`}>
          {i.NO_TITULO}
        </a>
      </NewsTitle>
      <NewsData>{Parsedata(i.NO_DATA).toUpperCase()}</NewsData>
    </Box>
  ));

  return (
    <Background id="sky-news">
      <Title>Sky news</Title>
      <Description>Confira as novidades da Sky eventos.</Description>
      <News>{RenderSkyNews}</News>
      <Button href="/noticias">ver mais notícias</Button>
    </Background>
  );
}

export default withRouter(ComponentSkyNews);
