import styled from "styled-components";

export const Background = styled.section`
  display: flex;
  background-color: #2B3476;
  flex-direction: row;
  position: relative;
  width: 100%;
  padding-top: 55.5px;
  padding-bottom: 55.5px;
`;

export const Content = styled.section`
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1150px) {
    padding-left: 25px;
    padding-right: 25px;
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`;
export const Box = styled.div`
  max-width: 33.33%;
  width: 100%;
  text-align: left;

  @media only screen and (max-width: 767px) {
    width: 100%;
    max-width: 100%;
    text-align: center;
    margin-bottom: 32px;

    img {
      width: 100%;
      max-width: 180px;
    }
  }
`;

export const Title = styled.h1`
  font-family: "PoppinsBold", sans-serif;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 24px;
`;

export const Description = styled.p`
  font-family: "BarlowMedium", sans-serif;
  font-size: 15px;
  line-height: 20px;
  color: #B7B7B7;
`;
