import styled from "styled-components";

export const Background = styled.section`
  display: flex;
  background-color: #2b3476;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding-top: 83px;
  padding-bottom: 72px;

  @media only screen and (max-width: 991px) {
    padding: 40px 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 991px) {
    padding: 0 25px;
  }
`;

export const Title = styled.h1`
  font-size: 72px;
  line-height: 60px;
  color: #fff;
  max-width: 578px;
  margin: 0 auto 16px auto;
  text-transform: uppercase;

  span.dot {
    color: #39c484;
  }

  @media only screen and (max-width: 991px) {
    padding: 0 25px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 60px;
    line-height: 62px;
    text-align: center;
  }

  @media only screen and (max-width: 425px) {
    font-size: 50px;
    line-height: 52px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 40px;
    line-height: 42px;
  }
`;

export const Description = styled.p`
  font-size: 20px;
  line-height: 26px;
  color: #fff;
  max-width: 425px;
  margin: 0 auto 48px auto;

  @media only screen and (max-width: 991px) {
    padding: 0 25px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 560px;
  margin: 0 auto 16px auto;
  padding: 16px 32px;
  background: #fff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 3px 3px 12px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 3px 3px 12px;
  -moz-box-shadow: rgba(0, 0, 0, 0.3) 3px 3px 12px;
  text-transform: uppercase;

  @media only screen and (max-width: 767px) {
    padding: 16px;
  }
`;

export const EventDay = styled.h2`
  font-family: "PoppinsBold", sans-serif;
  font-size: 42px;
  line-height: 46px;
  color: #39c484;
  text-align: center;
  justify-content: space-between;

  p {
    font-size: 17px;
    line-height: 21px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 32px;
    line-height: 36px;
  }
`;

export const Type = styled.h2`
  font-family: "PoppinsBold", sans-serif;
  font-size: 17px;
  line-height: 21px;
  color: #323b7e;
  justify-content: space-between;

  @media only screen and (max-width: 475px) {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  }
`;

export const Remain = styled.h2`
  font-family: "BarlowMedium", sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: #2f2f2f;
  justify-content: space-between;

  @media only screen and (max-width: 475px) {
    font-size: 13px;
    line-height: 23px;
    text-align: center;
  }
`;
