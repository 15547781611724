/* eslint-disable no-underscore-dangle */
import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

const { Creators, Types } = createActions(
  {
    request: ["postData", "onSuccess", "onFail"],
    success: [],
    failure: ["error"],
    reset: [],
  },
  { prefix: "CREATE_DEPOIMENTO_" }
);

const INITIAL_STATE = {
  loading: false,
  error: null,
};

const request = (state) =>
  update(state, {
    loading: { $set: true },
    error: { $set: null },
  });

const success = (state) =>
  update(state, {
    loading: { $set: false },
  });
const failure = (state, action) =>
  update(state, {
    loading: { $set: false },
    error: { $set: action.error },
  });

const reset = () => INITIAL_STATE;

export const createDepoimento = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const CreateDepoimentoTypes = Types;
export const CreateDepoimentoActions = Creators;
