import { createGlobalStyle } from "styled-components";
import FuturaBoldOblique from "../assets/fonts/Futura/futura-condensed-bold-oblique.ttf";
import Futura from "../assets/fonts/Futura/futura-condensed.ttf";
import BarlowMedium from "../assets/fonts/Barlow/Barlow-Medium.ttf";
import BarlowRegular from "../assets/fonts/Barlow/Barlow-Regular.ttf";
import PoppinsBold from "../assets/fonts/Poppins/Poppins-Bold.ttf";
import PoppinsMedium from "../assets/fonts/Poppins/Poppins-Medium.ttf";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "FuturaBold";
    src: url(${FuturaBoldOblique}) format("opentype");
  }

  @font-face {
    font-family: "FuturaRegular";
    src: url(${Futura}) format("opentype");
  }

  @font-face {
    font-family: "BarlowMedium";
    src: url(${BarlowMedium}) format("opentype");
  }

  @font-face {
    font-family: "BarlowRegular";
    src: url(${BarlowRegular}) format("opentype");
  }

  @font-face {
    font-family: "PoppinsBold";
    src: url(${PoppinsBold}) format("opentype");
  }

  @font-face {
    font-family: "PoppinsMedium";
    src: url(${PoppinsMedium}) format("opentype");
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }

  html,
  body,
  #root {
    height: 100%;
    scroll-behavior: smooth;


  }
  body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    background:#fff;
    font-family: "BarlowRegular", sans-serif;
    color:#717171;
    font-weight: normal;
    overflow-x: hidden;
  }
  a {
    text-decoration: none;
  }
  button {
    font-family: "FuturaRegular", sans-serif;
    border: 0;
    cursor: pointer;
    background-color: transparent;
  }
  input {
    font-family: "FuturaRegular", sans-serif;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: "FuturaBold", sans-serif;
  }
  ul {
    list-style: none;
  }
`;

export default GlobalStyle;
