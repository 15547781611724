import styled from "styled-components";

export const Background = styled.section`
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding-top: 72px;
  padding-bottom: 78px;

  @media only screen and (max-width: 991px) {
    padding: 40px 0;
  }
`;

export const Title = styled.h1`
  font-size: 72px;
  line-height: 53px;
  max-width: 580px;
  text-align: center;
  color: #e24a7e;
  margin: 0 auto 16px auto;
  text-transform: uppercase;

  @media only screen and (max-width: 767px) {
    font-size: 60px;
    line-height: 62px;
    text-align: center;
  }

  @media only screen and (max-width: 425px) {
    font-size: 50px;
    line-height: 52px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 40px;
    line-height: 42px;
  }
`;

export const Description = styled.p`
  font-size: 20px;
  line-height: 26px;
  color: #2f2f2f;
  max-width: 510px;
  text-align: center;
  margin: 0 auto;

  @media only screen and (max-width: 991px) {
    padding: 0 25px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
  }
`;

export const News = styled.section`
  display: flex;
  flex-direction: row;
  max-width: 1100px;
  width: 100%;
  margin: 48px auto 0 auto;

  @media only screen and (max-width: 991px) {
    flex-direction: column;
  }
`;

export const Box = styled.div`
  width:95%; 
  margin: 0 auto;
  text-align: center;
  &:last-child {
    margin-bottom:25px;
}
  img {
    width:95%;
    max-width: 600px;
    height:auto;
    object-fit: cover;
    @media only screen and (max-width: 600px) {
      width:100%;
    }
  }
  

`;

export const NewsTitle = styled.h1`
  font-family: "PoppinsBold", sans-serif;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: left;
  max-width: 275px;
  margin: 26px 0 14px 0;
  a {
    font-family: "PoppinsBold", sans-serif;
    font-size: 18px;
    color: #242f7c;
    font-weight: bold;
    letter-spacing: -0.6px;
  }
`;

export const NewsData = styled.p`
  font-family: "BarlowMedium", sans-serif;
  font-size: 15px;
  line-height: 19px;
  color: #656565;
  text-align: left;
`;

export const Button = styled.a`
  width: 100%;
  max-width: 220px;
  margin: 0 auto;
  padding: 14.5px 0;
  text-align: center;
  background-color: #1ea6c3;
  font-family: "PoppinsBold", sans-serif;
  font-size: 17px;
  line-height: 27px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 3px 3px 12px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 3px 3px 12px;
  -moz-box-shadow: rgba(0, 0, 0, 0.3) 3px 3px 12px;
`;
