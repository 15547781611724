import { call, put } from "redux-saga/effects";
import { apiErrorHandler } from "../../utils";
import { CreateDepoimentoActions } from "../ducks/createDepoimento";
import api from "../../services/api";
export function* createDepoimentoRequest(action) {
  try {
    const { postData, onSuccess } = action;
    if (onSuccess) onSuccess();
    yield call(api.post, "depoimentos", postData);
  } catch (error) {
    const { onFail } = action;
    const { errorMessage } = apiErrorHandler(error);
    if (onFail) onFail(errorMessage);
    yield put(CreateDepoimentoActions.failure(errorMessage));
  }
}
