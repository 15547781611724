import React from "react";

import { Background, Content, Box, Title, Description } from "./styles";

import Logo from "../../assets/images/menu/logo-sky.png";

export default function ComponentFooter() {
  return (
    <Background>
      <Content>
        <Box>
          <img src={Logo} alt="Logo" />
        </Box>
        <Box>
          <Title>Informações</Title>
          <Description>
            Ligue para o telefone: <br />
            (24) 3026-1352
          </Description>
        </Box>
        <Box>
          <Title>Localização</Title>
          <Description>
            Rua Capitão Benedito Lopes Bragança, N°381, São Geraldo, Volta
            Redonda/RJ - CEP: 27253-510
          </Description>
        </Box>
      </Content>
    </Background>
  );
}
