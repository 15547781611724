import { call, put } from "redux-saga/effects";
import { apiErrorHandler } from '../../utils';
import api, { queryBuilder } from "../../services/api";
import DepoimentosActions from "../ducks/depoimentos";

export function* fetch(action) {
  try {
    const { params = {} } = action;
    const query = queryBuilder(params);

    const url = `/depoimentos?${query}`;
    const { data } = yield call(api.get, url);

    yield put(DepoimentosActions.fetchSuccess(data));
  } catch (err) {
    const { errorMessage } = apiErrorHandler(err)
    yield put(DepoimentosActions.fetchFailure(errorMessage));
  }
}
