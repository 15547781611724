import styled from "styled-components";

import ImagemBtnAbrirMenu from "../../assets/images/menu/menu-hamburguer.svg";
import ImagemBtnFecharMenu from "../../assets/images/menu/close-button.svg";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
`;

export const Menu = styled.div`
  background-color: transparent;
  ${(props) => props.menuPosition && `position: ${props.menuPosition}`};
  z-index: 11;
  width: 100%;
  margin-top: 0 auto;

  @media only screen and (max-width: 650px) {
  }
`;

export const Logo = styled.div`
  display: inline-block;
  float: left;
  margin-left: 48px;

  img {
    width: 100%;
  }

  @media only screen and (max-width: 650px) {
    margin-left: 20px;

    img {
      max-width: 190px;
    }
  }
`;

export const BodyMenu = styled.div`
  width: 100%;
  display: ${(props) => (props.open ? "inline-block" : "none")};
  position: relative;
  z-index: 2;
  height: 100%;
  background-color: #323b7e;
  right: 0px;
  top: 0px;
  padding: 20px;
  overflow-y: auto;
  position: fixed;

  .BlockPages {
    margin-bottom: 32px;
    display: inline-block;
    width: 100%;
  }

  ul {
    text-align: center;
    /* margin-top: 42px; */
    margin-bottom: 60px;
  }
  ul li {
    display: inline-block;
    float: left;
    width: 100%;
    margin-top: 10px;
  }
  ul li a {
    font-family: "PoppinsBold", sans-serif;
    font-size: 34px;
    line-height: 56px;
    color: #fff;
    cursor: pointer;
    display: block;
  }

  ul.menu-red li a {
    color: #f4598e;
  }

  ul li.know {
    font-family: "PoppinsBold", sans-serif;
    color: #f6d52e;
    font-size: 16px;
    line-height: 26px;
    text-transform: uppercase;
    margin-top: 48px;
  }

  ul li.know-logo a {
    display: inline-block;
  }

  ul li.know-logo a.m-logo {
    margin-right: 24.5px;
  }

  hr {
    border: 1px solid #fff;
    max-width: 190px;
    margin: 0 auto 24px auto;
    opacity: 0.35;
  }

  @media only screen and (max-width: 767px) {
    padding: 20px 0;

    ul li a {
      font-size: 21px;
    }
  }

  @media only screen and (max-width: 650px) {
    .BlockPages {
      margin-bottom: 10px;
    }
  }
`;

export const BtnFecharModal = styled.div`
  a {
    background-image: url(${ImagemBtnFecharMenu});
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    background-size: 50px 50px;
    display: block;
    float: right;
    margin-right: 28px;
    margin-top: 28px;
    cursor: pointer;
  }

  @media only screen and (max-width: 650px) {
    a {
      margin-top: 0;
      margin-right: 9px;
    }
  }


  }
`;

export const BtnAbrirMenu = styled.a`
  background-image: url(${ImagemBtnAbrirMenu});
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  background-size: 50px 50px;
  cursor: pointer;
  display: block;
  float: right;
  margin-right: 48px;

  @media only screen and (max-width: 650px) {
    margin-right: 9px;
  }

  @media (max-width: 375px) {
    margin-right: 26px;
  }
`;
