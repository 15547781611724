import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaSpinner, FaSearch } from "react-icons/fa";

import GaleriasActions from "../../store/ducks/galerias";

import Menu from "../../components/Menu";
import Footer from "../../components/Footer";
import Paginator from "../../components/Paginator";

import {
  Container,
  Content,
  Title,
  Description,
  Events,
  Box,
  TitleSlider,
} from "./styles";

export default function Album() {
  const dispatch = useDispatch();

  const [query, setQuery] = useState({
    search: "",
    limit: 12,
  });

  const {
    data: galeriaData,
    pagination: galeriaPagination,
    loading: galeriaLoading,
  } = useSelector((state) => state.galerias);

  function handleSearch(event) {
    const { value } = event.target;
    setQuery((state) => ({ ...state, search: value }));
  }

  function handlePage(page) {
    dispatch(GaleriasActions.fetchRequest({ ...query, page }));
  }

  const getPosts = useCallback(() => {
    dispatch(GaleriasActions.fetchRequest(query));
  }, [dispatch, query]);

  useEffect(() => {
    getPosts();
  }, [getPosts]);

  return (
    <Container>
      <Menu />
      <Content>
        <Title>
          Nossas festas<span>.</span>
        </Title>
        <Description>
          Confira as fotos dos eventos produzidos pela Sky!
        </Description>
        <form onChange={handleSearch}>
          <div>
            {galeriaLoading ? (
              <FaSpinner size={24} className="icon-spin" />
            ) : (
              <FaSearch />
            )}

            <input name="search" placeholder="Buscar evento..." />
          </div>
        </form>
        {!galeriaLoading && galeriaData.length > 0 ? (
          <>
            <Events>
              {galeriaData.map((galeria) => (
                <a
                  href={`jarolou/${galeria.GA_URL}/${galeria.GA_COD}`}
                  title={galeria.GA_NOME}
                  alt={galeria.GA_NOME}>
                  <Box key={galeria.GA_COD}>
                    <img src={galeria.url} alt={galeria.GA_NONE} />
                    <TitleSlider>
                      <a href={`jarolou/${galeria.GA_URL}/${galeria.GA_COD}`}>
                        {galeria.GA_NOME}
                      </a>
                    </TitleSlider>
                  </Box>
                </a>
              ))}
            </Events>
            {galeriaPagination && (
              <Paginator
                pagination={galeriaPagination}
                onPageChange={handlePage}
              />
            )}
          </>
        ) : (
          <span>Nenhum resultado encontrado!</span>
        )}
      </Content>
      <Footer />
    </Container>
  );
}
