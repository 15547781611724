import styled from "styled-components";
import { shade } from "polished";

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  .containerMenu {
    background-color: #2b3476;
    padding: 14px 48px;

    a {
      top: 25px;
    }
  }

  @media only screen and (max-width: 767px) {
    .containerMenu {
      padding: 14px 0;
    }
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  min-height: 100%;
  margin: 0 auto;
  padding-top: 72px;
  padding-bottom: 88px;
  span {
    display: inline-block;
    margin: 0 auto;
  }

  form {
    text-align: center;
    margin-bottom: 58px;
    position: relative;

    div {
      position: relative;
      .icon-spin {
        -webkit-animation: icon-spin 2s infinite linear;
        animation: icon-spin 2s infinite linear;
      }

      @-webkit-keyframes icon-spin {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(359deg);
          transform: rotate(359deg);
        }
      }

      @keyframes icon-spin {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(359deg);
          transform: rotate(359deg);
        }
      }

      svg {
        position: absolute;
        width: 17px;
        height: 17px;
        top: 24px;
        margin-left: 20.5px;
        color: #323b7e;
      }

      input {
        font-family: "BarlowMedium", sans-serif;
        font-size: 17px;
        line-height: 26px;
        color: #2c2c2c;
        width: 100%;
        max-width: 610px;
        padding: 18px 20.5px 18px 45.5px;
        margin: 0 auto;
        border: 1px solid #909090;
        border-radius: 6px;
      }
    }

    @media only screen and (max-width: 991px) {
      padding-left: 25px;
      padding-right: 25px;
    }

    @media only screen and (max-width: 767px) {
      form {
        svg {
          top: 20px;
        }

        input {
          padding: 12px 20.5px 12px 45.5px;
        }
      }
    }
  }
`;

export const Title = styled.h1`
  font-size: 72px;
  line-height: 60px;
  max-width: 450px;
  text-align: center;
  color: #e24a7e;
  margin: 0 auto;
  text-transform: uppercase;

  span {
    color: #323b7e;
  }

  @media only screen and (max-width: 991px) {
    font-size: 60px;
    line-height: 62px;
    text-align: center;
  }

  @media only screen and (max-width: 425px) {
    font-size: 50px;
    line-height: 52px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 40px;
    line-height: 42px;
  }
`;

export const Description = styled.p`
  font-size: 20px;
  line-height: 26px;
  color: #2f2f2f;
  max-width: 510px;
  text-align: center;
  margin: 24px auto 40px auto;

  @media only screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
  }
`;

export const Events = styled.section`
  display: block;
  width: 100%;
  max-width: 1100px;
`;

export const Box = styled.div`
  display: inline-block;
  width: 30%;
  height: 470px;

  :nth-child(1n) {
    margin: 0 1%;
  }

  img {
    width: 100%;
    max-width: 340px;
    height: 280px;
    object-fit: cover;
  }

  @media only screen and (max-width: 767px) {
    display: block;
    width: 100%;

    :nth-child(2n) {
      margin: 48px 0;
    }
  }
`;

export const TitleSlider = styled.h2`
  font-family: "PoppinsBold", sans-serif;
  font-size: 20px;
  line-height: 32px;
  margin-top: 24px;
  text-transform: uppercase;
  max-width: 325px;
  height: 70px;
  display: flex;

  a {
    color: #242f7c;
    transition: 400ms;

    &:hover {
      color: ${shade(0.2, "#242f7c")};
    }
  }
`;

export const DescriptionSlider = styled.p`
  font-family: "PoppinsMedium", sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: #656565;
  margin-bottom: 24px;
  text-transform: uppercase;
  max-width: 325px;
`;
