import { all, takeLatest } from "redux-saga/effects";

import { NoticiaTypes } from "../ducks/noticia";
import { fetch as fetchNoticia } from "./noticia";

import { NoticiasTypes } from "../ducks/noticias";
import { fetch as fetchNoticias } from "./noticias";

import { GaleriasTypes } from "../ducks/galerias";
import { fetch as fetchGalerias } from "./galerias";

import { GaleriaTypes } from "../ducks/galeria";
import { fetch as fetchGaleria } from "./galeria";

import { DepoimentosTypes } from "../ducks/depoimentos";
import { fetch as fetchDepoimentos } from "./depoimentos";

import { EventosTypes } from "../ducks/eventos";
import { fetch as fetchEventos } from "./eventos";

import { ClubsTypes } from "../ducks/club";
import { fetch as fetchClub } from "./club";

import { CreateDepoimentoTypes } from "../ducks/createDepoimento";
import { createDepoimentoRequest } from "./createDepoimento";

export default function* rootSaga() {
  yield all([
    // noticia
    takeLatest(NoticiaTypes.FETCH_REQUEST, fetchNoticia),

    // noticias
    takeLatest(NoticiasTypes.FETCH_REQUEST, fetchNoticias),
    takeLatest(GaleriasTypes.FETCH_REQUEST, fetchGalerias),
    takeLatest(GaleriaTypes.FETCH_REQUEST, fetchGaleria),
    takeLatest(DepoimentosTypes.FETCH_REQUEST, fetchDepoimentos),
    takeLatest(EventosTypes.FETCH_REQUEST, fetchEventos),
    takeLatest(ClubsTypes.FETCH_REQUEST, fetchClub),
    //Depoimentos
    takeLatest(CreateDepoimentoTypes.REQUEST, createDepoimentoRequest),
  ]);
}
