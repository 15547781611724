import styled from "styled-components";

export const Background = styled.section`
  background-color: #2B3476;
  position: relative;
  width: 100%;
  padding-top: 160px;
  padding-bottom: 160px;

  @media only screen and (max-width: 767px) {
    padding: 40px 0 0 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const Text = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 991px) {
    padding: 0 25px;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-size: 82px;
  line-height: 66px;
  color: #fff;
  max-width: 486px;
  margin: 0 auto 26px auto;
  text-transform: uppercase;

  span.title-border {
    color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #F6D52E;
    margin-left: 37px;
  }

  span.dot {
    color: #F6D52E;
  }

  @media only screen and (max-width: 991px) {
    font-size: 60px;
    line-height: 62px;
    text-align: center;
  }

  @media only screen and (max-width: 767px) {
    span.title-border {
      margin-left: 0;
    }
  }

  @media only screen and (max-width: 425px) {
    font-size: 50px;
    line-height: 52px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 40px;
    line-height: 42px;
  }
`;
export const Description = styled.p`
  font-size: 18px;
  line-height: 26px;
  color: #fff;
  max-width: 425px;
  margin: 0 auto;

  @media only screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
  }
`;

export const Image = styled.div`
  width: 50%;

  img {
    width: 100%;
    object-fit: cover;
    float: right;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
    position: relative;
    bottom: -2.6px;
    margin-top: 32px;

    img {
      float: none;
    }
  }
`;
