import React, { useState, useEffect, useCallback } from "react";
import { Link, withRouter } from "react-router-dom";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import Swiper from "react-id-swiper";
import GaleriasActions from "../../store/ducks/galerias";

import "swiper/css/swiper.css";

import {
  Background,
  Slider,
  TitleSlider,
  Title,
  Description,
  Event,
  ButtonLink,
  Arrow,
} from "./styles";

function ComponentJaRolou() {
  const dispatch = useDispatch();

  const { data: dataGaleria } = useSelector((state) => state.galerias);

  const getGaleria = useCallback(() => {
    dispatch(GaleriasActions.fetchRequest());
  }, [dispatch]);

  useEffect(() => {
    getGaleria();
  }, [getGaleria]);

  const [swiper, updateSwiper] = useState(null);
  const settings = {
    slidesPerView: 3,
    spaceBetween: 100,
    speed: 500,
    centeredSlides: true,
    shouldSwiperUpdate: true,
    lazy: true,
    pagination: {
      clickable: true,
      renderBullet(index, className) {
        return `<span class="${className}"></span>`;
      },
    },
    breakpoints: {
      1920: {
        slidesPerView: 3,
        centeredSlides: false,
        spaceBetween: 1,
      },
      1440: {
        slidesPerView: 3,
        centeredSlides: false,
        spaceBetween: 20,
      },
      1366: {
        slidesPerView: 3,
        centeredSlides: false,
        spaceBetween: 55,
      },
      1280: {
        slidesPerView: 3,
        centeredSlides: false,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 2,
        centeredSlides: false,
        spaceBetween: 10,
      },
      934: {
        slidesPerView: 2,
        centeredSlides: false,
        spaceBetween: 10,
      },
      927: {
        slidesPerView: 2,
        centeredSlides: false,
        spaceBetween: 10,
      },
      640: {
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 40,
      },
      425: {
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 70,
      },
      375: {
        slidesPerView: 1,
        centeredSlides: true,
      },
      320: {
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 0,
      },
    },
  };
  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };
  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  return (
    <Background id="ja-rolou">
      <Title>
        O que já rolou<span className="dot">.</span>
      </Title>
      <Description>Garantimos diversão de verdade!</Description>
      <Event>
        <Swiper getSwiper={updateSwiper} {...settings}>
          {dataGaleria.map((i, index) => (
            <Slider key={index}>
              <img src={i.url} alt={i.GA_NOME} />
              <TitleSlider>
                <Link to={`jarolou/${i.GA_URL}/${i.GA_COD}`}>{i.GA_NOME}</Link>
              </TitleSlider>
            </Slider>
          ))}
        </Swiper>
        <Arrow direction="left" onClick={goPrev}>
          <MdChevronLeft />
        </Arrow>
        <Arrow direction="right" onClick={goNext}>
          <MdChevronRight />
        </Arrow>
      </Event>
      <ButtonLink href="/jarolou">Ver mais álbuns</ButtonLink>
    </Background>
  );
}
export default withRouter(ComponentJaRolou);
