import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Menu,
  Logo,
  BtnFecharModal,
  BtnAbrirMenu,
  BodyMenu,
} from "./styles";

import ImagemLogo from "../../assets/images/menu/logo-sky.png";
import LogoFacebook from "../../assets/images/menu/facebook-logo.svg";
import LogoInstagram from "../../assets/images/menu/instagram-logo.svg";

export default function CompontentMenu({ menuPosition }) {
  const [open, setOpen] = useState(false);

  const submenu = useRef();

  function handleClick() {
    setOpen(!open);
  }

  function handleClickClose() {
    setOpen(false);
  }

  function GetImageLogo() {
    return ImagemLogo;
  }

  return (
    <Container className="containerMenu">
      <Menu menuPosition={menuPosition}>
        <Logo>
          {/* <a href="/" alt="Logo">
            <img src={GetImageLogo()} />
          </a> */}
          <Link to="/">
            <img src={GetImageLogo()} alt="Logo" />
          </Link>
        </Logo>

        <BtnAbrirMenu onClick={() => handleClick()} />

        <BodyMenu ref={submenu} open={open}>
          <BtnFecharModal>
            <a onClick={() => handleClickClose()} />
          </BtnFecharModal>

          <div className="BlockPages">
            <ul>
              <li>
                <a href="/#ja-rolou" onClick={() => handleClickClose()}>
                  O que já rolou
                </a>
              </li>
              <li>
                <a href="/#vai-rolar" onClick={() => handleClickClose()}>
                  O que vai rolar
                </a>
              </li>
              <li>
                <a href="/#sky-club" onClick={() => handleClickClose()}>
                  Sky Club
                </a>
              </li>
              <li>
                <a href="/#sobre" onClick={() => handleClickClose()}>
                  Sobre a Sky
                </a>
              </li>
              <li>
                <a href="/#sky-news" onClick={() => handleClickClose()}>
                  Sky News
                </a>
              </li>
            </ul>
          </div>
          <hr />
          <div className="BlockPages">
            <ul className="menu-red">
              <li>
                <a
                  href="https://formando.online/index.aspx?ReturnUrl=%2f"
                  target="_blank"
                  rel="noreferrer">
                  Área do Formando
                </a>
              </li>
              <li>
                <a href="/#evento" onClick={() => handleClickClose()}>
                  Realize seu Evento
                </a>
              </li>
            </ul>
          </div>
          <div className="BlockPages">
            <ul>
              <li className="know">
                <span>Siga-nos:</span>
              </li>
              <li className="know-logo">
                <a
                  href="https://www.instagram.com/sejaskyeventos/"
                  target="_blank"
                  className="m-logo">
                  <img src={LogoInstagram} alt="Instagram" />
                </a>
                <a
                  href="https://www.facebook.com/sejaskyeventos/"
                  target="_blank">
                  <img src={LogoFacebook} alt="Facebook" />
                </a>
              </li>
            </ul>
          </div>
        </BodyMenu>
      </Menu>
    </Container>
  );
}
