import { call, put } from "redux-saga/effects";

import api from "../../services/api";
import GaleriaActions from "../ducks/galeria";

export function* fetch(action) {
  try {
    const { id } = action.params;
    const { data } = yield call(api.get, `galeria/${id}`);

    yield put(GaleriaActions.fetchSuccess(data));
  } catch (err) {
    yield put(GaleriaActions.fetchFailure(err.response.data.error.message));
  }
}
