import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import Swiper from "react-id-swiper";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import closeButton from "../../assets/images/comentarios/closeButton.svg";

import "swiper/css/swiper.css";

import {
  Box,
  Background,
  Title,
  Description,
  Event,
  Arrow,
  Modal,
  BoxModal,
  BtnFecharModal,
  Nome,
  Comentario,
} from "./styles";
import ClubActions from "../../store/ducks/club";

function ComponentSkyClub() {
  const dispatch = useDispatch();
  const { data: dataParceiros } = useSelector((state) => state.clubs);
  const [swiper, updateSwiper] = useState(null);
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);

  function handleShow(CL_COD) {
    const filter = dataParceiros.filter(
      (comentario) => comentario.CL_COD === CL_COD
    );
    setSelected(filter[0]);
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function htmlTextToString(htmlText) {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = htmlText;
    return textArea.value;
  }

  const getPosts = useCallback(() => {
    dispatch(ClubActions.fetchRequest());
  }, [dispatch]);

  useEffect(() => {
    getPosts();
  }, [getPosts]);

  const params = {
    slidesPerView: 4,
    spaceBetween: 100,
    speed: 500,
    centeredSlides: true,
    shouldSwiperUpdate: true,
    lazy: true,
    pagination: {
      clickable: true,
    },
    breakpoints: {
      1440: {
        slidesPerView: 3,
        centeredSlides: false,
        slidesPerColumn: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        centeredSlides: false,
        slidesPerColumn: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        centeredSlides: false,
        slidesPerColumn: 2,
        spaceBetween: 20,
      },
      425: {
        slidesPerView: 1,
        centeredSlides: false,
        slidesPerColumn: 1,
        spaceBetween: 20,
      },
      375: {
        slidesPerView: 1,
        centeredSlides: true,
        slidesPerColumn: 1,
        spaceBetween: 20,
      },
      320: {
        slidesPerView: 1,
        centeredSlides: true,
        slidesPerColumn: 1,
        spaceBetween: 20,
      },
    },
  };
  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };
  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  const RenderdatasParceiros = dataParceiros.map((i) => (
    <Box key={i.CL_COD} onClick={() => handleShow(i.CL_COD)}>
      <img src={i.url} alt="Parceiro" />
    </Box>
  ));

  return (
    <Background id="sky-club">
      <Title>Sky club</Title>
      <Description>
        Clientes da Sky Eventos tem uma série de benefícios em lojas e
        estabelecimentos parceiros da região. Clique e confira:
      </Description>
      <Event>
        <Swiper getSwiper={updateSwiper} {...params}>
          {RenderdatasParceiros}
        </Swiper>
        <Arrow direction="left" onClick={goPrev}>
          <MdChevronLeft />
        </Arrow>
        <Arrow direction="right" onClick={goNext}>
          <MdChevronRight />
        </Arrow>
      </Event>

      <Modal open={open}>
        <BoxModal>
          <BtnFecharModal onClick={handleClose}>
            <img src={closeButton} />
          </BtnFecharModal>
          <Nome>{selected && selected.CL_TITULO}</Nome>
          <Comentario dangerouslySetInnerHTML={{__html:`${selected?.CL_TEXTO}`}}>
            {/* {htmlTextToString(
              selected && selected.CL_TEXTO.replace(/(<([^>]+)>)/gi, "")
            )} */}

          </Comentario>
        </BoxModal>
      </Modal>
    </Background>
  );
}

export default withRouter(ComponentSkyClub);
