import styled, { css } from "styled-components";

import LeftButton from "../../assets/images/icons/arrow-left.svg";
import RightButton from "../../assets/images/icons/arrow-right.svg";
import ImagemBtnFecharMenu from "../../assets/images/menu/close-button.svg";

export const Background = styled.section`
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding-top: 83px;
  padding-bottom: 96px;

  @media only screen and (max-width: 991px) {
    padding: 40px 0;
  }
`;

export const Box = styled.div`
  min-height: 181px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 16px;
  cursor:pointer;

  img {
    margin: 0 auto;
  }

  @media only screen and (min-width: 768px) {
    /* max-width: 245px; */
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-size: 72px;
  line-height: 76px;
  max-width: 265px;
  text-align: center;
  color: #e24a7e;
  margin: 0 auto 16px auto;
  text-transform: uppercase;

  @media only screen and (max-width: 767px) {
    font-size: 60px;
    line-height: 62px;
    text-align: center;
  }

  @media only screen and (max-width: 425px) {
    font-size: 50px;
    line-height: 52px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 40px;
    line-height: 42px;
  }
`;

export const Description = styled.p`
  font-size: 20px;
  line-height: 26px;
  color: #2f2f2f;
  text-align: center;
  max-width: 566px;
  margin: 0 auto;

  @media only screen and (max-width: 991px) {
    padding: 0 25px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
  }
`;

export const Event = styled.div`
  flex-direction: row;
  width: 100%;
  max-width: 1300px;
  padding: 0 70px;
  margin-top: 44px;
  margin-left: auto;
  margin-right: auto;

  .swiper-container-multirow-column > .swiper-wrapper {
    flex-direction: row;
    justify-content: center;
  }

  .swiper-slide {
    margin-top: 20px !important;
  }

  .button-prev {
    background-image: url(${LeftButton});
    background-repeat: no-repeat;
    width: 48px;
    height: 48px;
    position: absolute;
    background-size: 48px 48px;
    left: 0;
    top: 50%;
    cursor: pointer;
    z-index: 1;
  }

  .button-next {
    background-image: url(${RightButton});
    background-repeat: no-repeat;
    width: 48px;
    height: 48px;
    position: absolute;
    background-size: 48px 48px;
    right: 0;
    top: 50%;
    cursor: pointer;
    z-index: 1;
  }

  /* .swiper-wrapper .swiper-slide-active {
    padding-left: 80px;
  }

  .swiper-wrapper .swiper-slide-next {
    padding: 0 40px;
  }

  .swiper-wrapper .swiper-slide-duplicate-prev {
    padding-right: 80px;
  } */
`;

export const Arrow = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 35px;
  height: 35px;
  background-color: #fff;
  color: #242f7c;
  border-radius: 45px;
  border: none;

  ${(props) =>
    props.direction === "left"
      ? css`
          left: 5%;
        `
      : css`
          right: 5%;
        `}

  top: 60%;
  z-index: 2;
  font-size: 3.2rem;
  @media (max-width: 1024px) {
    ${(props) =>
      props.direction === "left"
        ? css`
            left: 2%;
          `
        : css`
            right: 2%;
          `}
  }

  @media (max-width: 425px) {
    ${(props) =>
      props.direction === "left"
        ? css`
            left: 5%;
          `
        : css`
            right: 5%;
          `}
    top: 66%;
  }
  @media (max-width: 375px) {
    ${(props) =>
      props.direction === "left"
        ? css`
            left: 5%;
          `
        : css`
            right: 5%;
          `}
  }

  @media screen and (max-device-width: 320px) and (-webkit-min-device-pixel-ratio: 1) {
    ${(props) =>
      props.direction === "left"
        ? css`
            left: 14%;
          `
        : css`
            right: 14%;
          `}

    svg {
      position: relative;
      top: -8px;
    }
  }
`;

export const Modal = styled.div`
  display: ${(props) => (props.open ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 50;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const BoxModal = styled.div`
  position: relative;
  background-color: #fafafa;
  border-radius: 5px;
  width: 100%;
  max-width: 768px;
  height: 70%;
  max-height: 600px;
  text-align: center;
  padding: 100px;

  img {
    width: 100%;
    max-width: 115px;
    border-radius: 50%;
  }

  ${() => `
    ${Comentario} {
      padding: 0 40px;
      max-height: 270px;
      overflow: auto;
    }
  `}

  @media (max-width: 768px) {
    width: 90%;
  }

  @media only screen and (max-width: 575px) {
    width: 85%;
    height: 90%;

    ${() => `
      ${Comentario} {
        padding: 0;
        max-width: 220px;
        max-height: 230px;
      }
    `}
  }

  @media only screen and (max-width: 425px) {
    width: 85%;
    height: 90%;
    padding: 35px;

    ${() => `
      ${Comentario} {
        padding: 0;
        max-width: 300px;
        max-height: 400px;
      }
    `}
  }
  @media only screen and (max-width: 320px) {
    width: 85%;
    height: 90%;
    padding: 35px;

    ${() => `
      ${Comentario} {
        padding: 0;
      max-width: 300px;
      max-height: 320px;
      }
    `}
  }
`;

export const Comentario = styled.p`
  font-family: "BarlowMedium", sans-serif;
  font-size: 15px;
  line-height: 19px;
  text-align:left;
  line-height:1.5em;
  color: #666;
  margin-top: 24px;
  

  &.limit-text {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  @media only screen and (max-width: 480px) {
    max-width: 240px;
    margin: 0 auto;
  }

  @media (max-width: 425px) {
    padding: 0;
    max-width: 300px;
    max-height: 400px;
    text-align: justify;
    margin-top: 24px;
  }

  @media only screen and (max-width: 375px) {
    max-width: 180px;
    padding: 0;
    max-width: 300px;
    max-height: 327px;
  }
`;

export const BtnFecharModal = styled.a`
  position: absolute;
  max-width: 32px;
  top: -20px;
  right: -20px;
  cursor: pointer;
`;

export const Nome = styled.h2`
  font-family: "PoppinsBold", sans-serif;
  font-size: 36px;
  line-height: 32px;
  margin-top: 16px;
  color: #144a65;
  text-transform: uppercase;
`;
