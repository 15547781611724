import styled, { css } from "styled-components";
import { shade } from "polished";

export const Background = styled.section`
  display: flex;
  background-color: #fff;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding-top: 74px;
  padding-bottom: 94px;

  @media only screen and (max-width: 767px) {
    padding: 40px 0;
  }
`;

export const Slider = styled.div`
  width: 100%;
  img {
    padding-left: 3px;
    object-fit: cover;
  }

  @media (max-width: 425px) {
    img {
      padding-left: 0;
      object-fit: cover;
    }
  }
`;

export const TitleSlider = styled.h2`
  font-family: "PoppinsBold", sans-serif;
  line-height: 32px;
  margin-top: 32px;
  text-transform: uppercase;

  a {
    color: #242f7c;
    letter-spacing: -1px;

    &:hover {
      color: ${shade(0.2, "#242f7c")};
    }
  }
`;

export const DescriptionSlider = styled.p`
  font-family: "PoppinsMedium", sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: #656565;
  text-transform: uppercase;
`;

export const Title = styled.h1`
  font-size: 72px;
  line-height: 60px;
  color: #2b3476;
  margin: 0 auto 16px auto;
  text-transform: uppercase;

  span.dot {
    color: #e24a7e;
  }

  @media only screen and (max-width: 991px) {
    font-size: 60px;
    line-height: 62px;
    text-align: center;
  }

  @media only screen and (max-width: 425px) {
    font-size: 50px;
    line-height: 52px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 40px;
    line-height: 42px;
  }
`;

export const Description = styled.p`
  font-size: 20px;
  line-height: 26px;
  color: #2f2f2f;
  margin: 0 auto;

  @media only screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
  }
`;

export const Event = styled.div`
  max-width: 90%;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-top: 30px;

  img {
    width: 423px;
    height: 370px;
    background-size: cover;
    object-fit: cover;
  }
  @media (max-width: 2560px) {
    img {
      width: 94%;
      object-fit: cover;
    }
  }
  @media (max-width: 1920px) {
    img {
      width: 94%;
      object-fit: cover;
    }
  }
  @media (max-width: 1440px) {
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  @media (max-width: 1024px) {
    max-width: 85%;
  }
  @media (max-width: 768px) {
    max-width: 57%;
  }
  @media (max-width: 425px) {
    max-width: 80%;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  @media (max-width: 375px) {
    max-width: 90%;
  }
`;

export const ButtonLink = styled.a`
  width: 100%;
  max-width: 220px;
  margin: 43px auto 0 auto;
  padding: 15px 0;
  text-align: center;
  background-color: #e24a7e;
  font-family: "PoppinsBold", sans-serif;
  font-size: 17px;
  line-height: 27px;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 5px;
  transition: background-color 400ms;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 3px 3px 12px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 3px 3px 12px;
  -moz-box-shadow: rgba(0, 0, 0, 0.3) 3px 3px 12px;

  &:hover {
    background-color: ${shade(0.2, "#e24a7e")};
  }
`;

export const Arrow = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 35px;
  height: 35px;
  background-color: #fff;
  color: #242f7c;
  border-radius: 45px;
  border: none;

  svg {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${(props) =>
    props.direction === "left"
      ? css`
          left: 10%;
        `
      : css`
          right: 9%;
        `}
  top: 41%;
  z-index: 2;
  font-size: 3.2rem;

  @media (max-width: 425px) {
    ${(props) =>
      props.direction === "left"
        ? css`
            left: 14%;
          `
        : css`
            right: 14%;
          `}
    top: 40%;
  }

  @media (max-width: 375px) {
    ${(props) =>
      props.direction === "left"
        ? css`
            left: 10%;
          `
        : css`
            right: 10%;
          `}
    top: 40%;
  }

  @media screen and (max-device-width: 320px) and (-webkit-min-device-pixel-ratio: 1) {
    ${(props) =>
      props.direction === "left"
        ? css`
            left: 14%;
          `
        : css`
            right: 14%;
          `}
    top: 40%;
    svg {
      position: relative;
      top: -8px;
    }
  }
`;
