import React from "react";

import {
  Background,
  Container,
  Text,
  Title,
  Description,
  Image,
} from "./styles";

import imageFormatura from "../../assets/images/formatura/formatura.jpg";

export default function ComponentFormatura() {
  return (
    <Background id="formatura-social">
      <Container>
        <Text>
          <Title>
            Formatura <span className="title-border">social</span> corporativo
            <span className="dot">.</span>
          </Title>
          <Description>
            Quem não sonha em celebrar uma data importante com uma super festa,
            ao lado das melhores companhias? Formaturas, casamentos,
            aniversários, eventos empresariais… Tudo fica mais incrível com a
            Sky por trás do seu evento.
          </Description>
        </Text>
        <Image>
          <img src={imageFormatura} alt="Formatura social corporativo" />
        </Image>
      </Container>
    </Background>
  );
}
