import React, { useEffect, useRef, useState } from "react";
import { useField } from "@unform/core";
import ReactInputMask from "react-input-mask";
import { Container } from "./styles";

export default function Input({
  name,
  label,
  required,
  type,
  value,
  onChange,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue = "", registerField, error } = useField(name);

  const [realValue, setRealValue] = useState(defaultValue);
  const [mask, setMask] = useState("");
  const [formatChars, setFormatChars] = useState({
    "9": "[0-9]",
    a: "[A-Z, a-z]",
    "*": "[A-Z, a-z, 0-9]",
  });

  function handleMask(event) {
    const { value: targetValue } = event.target;
    if (onChange) onChange(event);
    return setRealValue(targetValue);
  }

  useEffect(() => {
    console.log("valor de type", type);
    switch (type) {
      case "ano":
        setMask("9999");
        setFormatChars({ "9": "[0-9]", "?": "[0-9]" });
        break;
      case "celular":
        setMask("(99) 99999-9999");
        setFormatChars({ "9": "[0-9]", "?": "[0-9]" });
        break;

      default:
        setMask("");
        setFormatChars({
          "9": "[0-9]",
          a: "[A-Z, a-z]",
          "*": "[A-Z, a-z, 0-9]",
        });
    }
  }, [type]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "props.value",
      clearValue: (inputMask) => {
        inputMask.setInputValue(null);
      },
    });
    if (value) setRealValue(value);
  }, [inputRef, fieldName, value, registerField]);

  return (
    <Container>
      <ReactInputMask
        type="text"
        maskChar=""
        name={name}
        value={realValue || ""}
        mask={mask}
        formatChars={formatChars}
        onChange={(e) => handleMask(e)}
        ref={inputRef}
        {...rest}
      />
      {error && <span className="error">{error}</span>}
    </Container>
  );
}
