import styled from "styled-components";

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  .containerMenu {
    background-color: #2b3476;
    padding-top: 14px;
    padding-bottom: 14px;

    a {
      top: 25px;
    }
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  min-height: 100%;
  margin: 0 auto;
  padding-top: 72px;
  padding-bottom: 88px;

  section {
    text-align: center;
    margin-bottom: 58px;
    position: relative;

    div {
      position: relative;
      margin-bottom: 70px;

      .icon-spin {
        -webkit-animation: icon-spin 2s infinite linear;
        animation: icon-spin 2s infinite linear;
      }

      @-webkit-keyframes icon-spin {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(359deg);
          transform: rotate(359deg);
        }
      }

      @keyframes icon-spin {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(359deg);
          transform: rotate(359deg);
        }
      }
    }

    svg {
      position: absolute;
      width: 17px;
      height: 17px;
      top: 24px;
      margin-left: 20.5px;
      color: #323b7e;
    }

    input {
      font-family: "BarlowMedium", sans-serif;
      font-size: 17px;
      line-height: 26px;
      color: #2c2c2c;
      width: 100%;
      max-width: 610px;
      padding: 18px 20.5px 18px 45.5px;
      margin: 0 auto;
      border: 1px solid #909090;
      border-radius: 6px;
    }
  }

  @media only screen and (max-width: 991px) {
    padding-left: 25px;
    padding-right: 25px;
  }

  @media only screen and (max-width: 767px) {
    form {
      svg {
        top: 20px;
      }

      input {
        padding: 12px 20.5px 12px 45.5px;
      }
    }
  }
`;

export const Title = styled.h1`
  font-size: 72px;
  line-height: 60px;
  max-width: 450px;
  text-align: center;
  color: #e24a7e;
  margin: 0 auto;
  text-transform: uppercase;

  span {
    color: #323b7e;
  }

  @media only screen and (max-width: 991px) {
    font-size: 60px;
    line-height: 62px;
    text-align: center;
  }

  @media only screen and (max-width: 425px) {
    font-size: 50px;
    line-height: 52px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 40px;
    line-height: 42px;
  }
`;

export const Description = styled.p`
  font-size: 20px;
  line-height: 26px;
  color: #2f2f2f;
  max-width: 510px;
  text-align: center;
  margin: 24px auto 40px auto;

  @media only screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
  }
`;

export const Events = styled.section`
  display: block;
  width: 100%;
  max-width: 722px;
  margin: 0 auto 72px auto;
`;

export const Box = styled.div`
  display: block;
  width: 100%;

  :first-child,
  :nth-child(2n) {
    margin-bottom: 72px;
  }

  img {
    width: 100%;
    object-fit: cover;
  }

  h2 {
    margin-top: 24px;
    font-size: 24px;
    line-height: 28px;
  }

  p {
    margin-top: 32px;
  }

  @media only screen and (max-width: 425px) {
    h2 {
      font-size: 20px;
      line-height: 24px;
    }

    p {
      font-size: 15px;
      line-height: 20px;
    }
  }

  @media only screen and (max-width: 375px) {
    h2 {
      font-size: 18px;
      line-height: 22px;
    }

    p {
      font-size: 13px;
      line-height: 17px;
    }
  }
`;

export const TitleNews = styled.h2`
  font-family: "PoppinsBold", sans-serif;
  text-align: left;
  line-height: 32px;
  margin-bottom: 1px;

  text-transform: uppercase;
  a {
    color: #242f7c;
    font-size: 24px;
    font-weight: bold;
  }
`;

export const DescriptionNews = styled.div`
  font-family: "Barlow", sans-serif;
  text-align: left;
  font-size: 20px;
  color: #2f2f2f;
  line-height: 26px;

  &.limit-text {
    max-height: 113px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
`;
