import styled from "styled-components";

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  .containerMenu {
    background-color: #2b3476;
    padding: 14px 48px;

    a {
      top: 25px;
    }
  }

  @media only screen and (max-width: 767px) {
    .containerMenu {
      padding: 14px 0;
    }
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  min-height: 100%;
  margin: 0 auto;
  padding-top: 72px;
  padding-bottom: 88px;

  form {
    text-align: center;
    margin-bottom: 58px;

    div {
      position: relative;
    }

    svg {
      position: absolute;
      width: 17px;
      height: 17px;
      top: 24px;
      margin-left: 20.5px;
      color: #323b7e;
    }

    input {
      font-family: "BarlowMedium", sans-serif;
      font-size: 17px;
      line-height: 26px;
      color: #2c2c2c;
      width: 100%;
      max-width: 610px;
      padding: 18px 20.5px 18px 45.5px;
      margin: 0 auto;
      border: 1px solid #909090;
      border-radius: 6px;
    }
  }

  @media only screen and (max-width: 991px) {
    padding-left: 25px;
    padding-right: 25px;
  }

  @media only screen and (max-width: 767px) {
    form {
      svg {
        top: 20px;
      }

      input {
        padding: 12px 20.5px 12px 45.5px;
      }
    }
  }
`;

export const Title = styled.h1`
  font-size: 49px;
  line-height: 60px;
  max-width: 645px;
  text-align: center;
  color: #e24a7e;
  margin: 0 auto;
  text-transform: uppercase;

  font-size: 49px;
  line-height: 60px;
  max-width: 645px;

  span {
    color: #323b7e;
  }

  @media only screen and (max-width: 991px) {
    font-size: 60px;
    line-height: 62px;
    text-align: center;
  }

  @media only screen and (max-width: 425px) {
    font-size: 50px;
    line-height: 52px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 40px;
    line-height: 42px;
  }
`;

export const Description = styled.p`
  font-size: 20px;
  line-height: 26px;
  color: #2f2f2f;
  max-width: 510px;
  text-align: center;
  margin: 24px auto 40px auto;

  @media only screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
  }
`;

export const Events = styled.section`
  display: block;
  width: 100%;
  max-width: 1100px;
`;

export const Box = styled.div`
  display: inline-block;
  width: 30%;
  overflow: hidden;

  :nth-child(1n) {
    margin: 0 1%;
    margin-bottom: 1%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 340px;
    height: 280px;

    transition: all linear 0.4s;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }

  @media only screen and (max-width: 767px) {
    display: block;
    width: 100%;

    :nth-child(2n) {
      margin: 48px 0;
    }
  }
`;

export const TitleSlider = styled.h2`
  font-family: "PoppinsBold", sans-serif;
  font-size: 20px;
  line-height: 32px;
  margin-top: 24px;
  color: #242f7c;
  text-transform: uppercase;
`;

export const DescriptionSlider = styled.p`
  font-family: "PoppinsMedium", sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: #656565;
  margin-bottom: 24px;
  text-transform: uppercase;
`;

export const Modal = styled.div`
  display: ${(props) => (props.open ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 50;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const BoxModal = styled.div`
 position: relative;
    border-radius: 5px;
    text-align: center;
    padding: 40px 0;

  img {
    width: 100%;
    object-fit: cover;
    max-width: 100vw;
    max-height: 70vh;

  }


  @media only screen and (max-width: 575px) {
    width: 85%;
    height: 90%;


  }
}

`;

export const BtnFecharModal = styled.a`
  position: absolute;
  max-width: 37px;
  top: 10px;
  right: -26px;
  cursor: pointer;
`;

export const Back = styled.div`
  a {
    display: flex;
    align-items: center;
    &:hover {
      color: #242f7c;
    }
  }
  svg {
    color: #242f7c;
    font-size: 24px;
    margin-right: 8px;
  }
`;
