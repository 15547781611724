import { call, put } from "redux-saga/effects";

import api, { queryBuilder } from "../../services/api";
import EventosActions from "../ducks/eventos";

export function* fetch(action) {
  try {
    const { params = {} } = action;

    const query = queryBuilder(params);

    const url = `/eventos?${query}`;
    const { data } = yield call(api.get, url);

    yield put(EventosActions.fetchSuccess(data));
  } catch (err) {
    yield put(EventosActions.fetchFailure(err.response.data.error.message));
  }
}
