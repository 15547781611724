import styled from "styled-components";

export const Container = styled.div`
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding-top: 72px;
  padding-bottom: 78px;

  @media only screen and (max-width: 991px) {
    padding: 40px 0;
  }
`;

export const Title = styled.h1`
  font-size: 72px;
  line-height: 60px;
  max-width: 580px;
  text-align: center;
  color: #e24a7e;
  margin: 0 auto 16px auto;
  text-transform: uppercase;

  @media only screen and (max-width: 767px) {
    font-size: 60px;
    line-height: 62px;
    text-align: center;
  }

  @media only screen and (max-width: 425px) {
    font-size: 50px;
    line-height: 52px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 40px;
    line-height: 42px;
  }
`;

export const Description = styled.p`
  font-size: 20px;
  line-height: 26px;
  color: #2f2f2f;
  max-width: 510px;
  text-align: center;
  margin: 0 auto;

  @media only screen and (max-width: 991px) {
    padding: 0 25px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
  }
`;
export const RedesSociais = styled.div`
  font-family: "Poppins";
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;

  @media (max-width: 375px) {
    display: block;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8387a4;
    svg {
      display: inline-block;
      margin-right: 10px;
      font-size: 27px;
      color: #242f7c;
    }
  }

  span + span {
    margin-left: 10px;
  }
`;
