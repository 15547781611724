import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import { Container } from "./styles";

const InputFile = ({ name, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "files[0]",
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <input ref={inputRef} defaultValue={defaultValue} {...rest} />
      {error && <span className="error">{error}</span>}
    </Container>
  );
};

export default InputFile;
