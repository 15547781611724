import React from "react";
import { scroller } from "react-scroll";

import { Banner, Text, Title, Description, Button } from "./styles";

import imageBanner from "../../assets/images/banner/banner.jpg";
import imageButton from "../../assets/images/banner/button.svg";

import Menu from "../Menu";

export default function ComponentBanner() {
  return (
    <Banner>
      <img src={imageBanner} alt="Banner" className="background" />
      <Menu />
      <Text>
        <Title>
          <span className="title-border">Para viver</span> cada momento
          <span className="dot">.</span>
        </Title>
        <Description>
          Descubra o que a Sky pode fazer pelo seu Evento.
        </Description>
      </Text>
      <Button href="#formatura-social">
        Rolar
        <img src={imageButton} alt="Rolar" />
      </Button>
    </Banner>
  );
}
